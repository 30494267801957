@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./vendor/fancybox.scss";
@import "./vendor/jquery-ui.scss";
@import "./vendor/lightslider.scss";
@import "./vendor/modal.scss";
@import "./vendor/starz.scss";
@import "./print.scss";
//@import "./_buttons.scss";


/*
---------------- Table of content --------------------

1: Media breakpoints
1: Generic styles
2: Fonts
3: Header
4: Footer
5: Content

/* ----------------  2: Media breakpoints --------------------- */

// Media breakpoints
$xs: 320px;
$xs-max: 414px;
$sm: 415px;
$sm-max: 767px;
$md: 768px;
$md-max: 991px;
$lg: 992px;

/* ----------------  1: Generic styles / Variables --------------------- */

@font-face {
    font-family: "Leasy";
    src: url('/Leasy.Assets/dist/fonts/Leasy/VariableFont/LeasyVariableVF.ttf') format('truetype');
    font-weight: normal; /* Adjust this if your WOFF2 is bold */
    font-style: normal;
}

$font-roboto: 'Leasy', serif;
$font-source: 'Leasy', sans-serif;

.info.debitor__consent--l {

    @media (max-width: $sm) {
        padding: 2rem 0;
    }

    .rte h1 {
        font-size: 28px;
        font-weight: 600;
        word-break: break-word;
        margin-bottom: .5rem;

        @media (min-width: $xs-max) {
            font-size: 32px;
            margin-bottom: 1rem;
        }

        @media (min-width: $md) {
            font-size: 42px;
            margin-bottom: 2rem;
        }

        @media (min-width: $lg) {
            font-size: 50px;
        }
    }

    .rte h3 {
        font-size: 36px;
        font-weight: 600;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }
}



section {

    @media (min-width: 991px) and (max-width: 1100px) {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

img {
    max-width: 100%;
    height: auto;
}

body,
html {
    min-height: 100%;
    min-width: 100%;
}

body {
    overflow-x: hidden;
    background: #ededed;
    font-size: 16px;

    &.modal-open {
        overflow: hidden;
    }
}

.container {
    width: 100%;
    margin: 0 auto;
}

.wrapper {
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
    position: relative;

    @media (min-width: 0) and (max-width: $md-max) {
        padding: 0 20px;
    }
}

.align-right {
    text-align: right;
}

.bg-white {
    background: #fff;
}

.bg-dark-grey {
    background-color: #4a4a4a !important;
    color: #fff;

    .wrapper {

        &.info {
        }

        .bg-white {
            background: transparent;
        }

        h2 {
            padding: 2rem 0;
        }
    }
}

.bg-light-grey {
    background: #ededed !important;

    .wrapper {
        background: white;

        &.info {
        }

        h2 {
            padding: 2rem 0;
        }
    }
}

.loan-blue {
    color: #4196ba;
}

.col-full {
    width: 100%;
}

.col-half {
    width: 50%;
}

.col-two-third {
    width: 100%;
    max-width: 620px;

    @media (max-width: $md) {
        max-width: 100%;
    }

    margin-right: 115px;
    float: left;

    @media (min-width:980px) and (max-width: 1024px) {
        margin-right: 15px;
    }
}

.col-one-third {
    width: 100%;
    max-width: 345px;

    @media (max-width: $md) {
        max-width: 100%;
    }

    float: left;
}


/* ----------------  2: Fonts & buttons --------------------- */

h1,
h2,
h3,
h4,
h5 {
    padding: 0;
    margin: 0;
}

h1,
.h1 {
    font-size: 50px;
    line-height: 52px;
    font-family: $font-roboto;
    font-weight: 700;

    @media (max-width: $sm-max) {
        font-size: 32px;
    }
}

h2,
.h2 {
    font-size: 35px;
    line-height: 40px;
    font-weight: 700;
    margin-bottom: 35px;
    font-family: $font-roboto;

    @media (max-width: $sm-max) {
        font-size: 24px;
    }

    &.product-title {
        font-size: 25px;
        font-weight: 300;
        font-family: $font-source;
        margin-bottom: 10px;
    }

    &.modal-title {
        font-family: $font-roboto;
        font-size: 25px;
    }

    .basket-info & {
        margin-bottom: 25px;
    }
}

h3,
.h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    font-family: $font-roboto;
}

h4,
.h4 {
    font-size: 20px;
    line-height: 24px;
    font-family: $font-source;
    font-weight: 200;
    margin-bottom: 14px;

    .loan-basket-info & {
        font-size: 25px;
        font-family: $font-roboto;
        margin-top: 0;
        border-bottom: 3px solid #ededed;
        padding-bottom: 10px;
    }
}

h5,
.h5 {
    font-family: $font-roboto;
    font-size: 17px;
    font-weight: 700;
    line-height: 23px;
}

h6,
.h6 {
    font-size: 15px;
    font-weight: 700;
    line-height: 21px;
    font-family: $font-roboto;
}

strong, b {
    font-weight: bold;
}

i {
    font-style: italic;
}

a.btn, button {
    cursor: pointer;
    border: 1px solid #fff;
    background-color: transparent;
    font-family: $font-roboto;
    color: #fff;
    text-decoration: none;
    position: relative;

    &.cta-green {
        background: #3ca54b; /* Old browsers */
        box-shadow: none;
        text-align: center;
        border: none;
        -webkit-appearence: inherit;

        &.frontpageBanner {
            margin-top: 50px;
            border: 2px solid #fff;
        }
    }

    &.cta-black {
        background: #000;
        box-shadow: none;
        text-align: center;
        border: 2px solid #fff;
        -webkit-appearence: inherit;
        margin-top: 50px;
    }

    &.cta-grey {
        background: #999999;
        box-shadow: none;
        text-align: center;
        border: 1px solid #fff;
        -webkit-appearence: inherit;
        margin-top: 35px;
    }

    .loan-center--cta & {
        background: #3ca54b; /* Old browsers */
        background: -moz-linear-gradient(top, #3ca54b 0%, #3ca54b 50%, #148228 54%, #148228 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, #3ca54b 0%,#3ca54b 50%,#148228 54%,#148228 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, #3ca54b 0%,#3ca54b 50%,#148228 54%,#148228 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        box-shadow: 2px 2px 1px #afafaf;
        text-align: center;
        border: none;
        min-width: 200px;
        text-transform: uppercase;
    }

    &.frontpageBanner--lm {
        margin-top: 24px;
    }
}

a {
    display: inline-block;
    text-decoration: none;

    &.cta-about {
        text-decoration: none;
        margin-top: 35px;
    }

    .advice & {
        text-decoration: none;
    }

    .bg-dark-grey & {
        color: #fff;
    }
}

p {
    font-family: $font-source;

    &.roboto {
        font-family: $font-roboto;
    }

    &.source {
        font-family: $font-source;
    }

    &.small {
        font-size: 13px;
        line-height: 16px;
    }

    &.x-small {
        font-size: 11px;
        line-height: 14px;

        &.grey {
            color: #999999;
        }

        &.white {
            color: #fff;
        }
    }

    &.medium {
        font-size: 15px;
        line-height: 22px;
    }

    &.large {
        font-size: 17px;
        line-height: 25px;
    }

    &.bold {
        font-weight: 600;
    }

    &.qoute {
        font-style: italic;
        padding-bottom: 10px;

        &:before {
            content: '"';
        }

        &:after {
            content: '"';
        }
    }

    &.secure {
        font-size: 13px;
        font-family: $font-source;
        color: #999999;
    }

    .info--text-column & {
        line-height: 22px;
    }
}

ul {
    &.text-column-list {
        padding-left: 20px;

        li {
            line-height: 22px;
        }
    }

    &.product-bullets {
        padding-left: 20px;
        margin-bottom: 15px;
    }
}

.uppercase {
    text-transform: uppercase;
}

.center-text {
    text-align: center;
}

.left-align {
    text-align: left;
}

.right-align {
    text-align: right;
}

.bold {
    font-weight: bold;
}

.underlined {
    text-decoration: underline;
}

input,
select {
    padding: 0 1.5rem;
    color: #000000;
    border-width: 1px;
    border-color: #b4b4b4;
    border-style: solid;

    &:focus {
        outline: none;
    }


    &[type=radio] {
        width: auto;
        height: auto;
    }

    &:-moz-placeholder {
        color: #000;
        font-size: 14px;
    }

    &::-moz-placeholder {
        color: #000;
        font-size: 14px;
    }

    &:-ms-input-placeholder {
        color: #000;
        font-size: 14px;
    }

    &::-webkit-input-placeholder {
        color: #000;
        font-size: 14px;
    }
}
// placeholders only visible in bank info formgroup (loanform)
.bankinfo input {
    &:-moz-placeholder {
        font-size: 14px;
    }

    &::-moz-placeholder {
        font-size: 14px;
    }

    &:-ms-input-placeholder {
        font-size: 14px;
    }

    &::-webkit-input-placeholder {
        font-size: 14px;
    }
}

select {
    position: relative;
}

input {
    color: #000;

    @media (min-width: 1150px) {
        .basket-info & {
            font-size: inherit;
        }

        .chat & {
            font-size: inherit;
        }
    }
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
    appearance: none;
}

.selector {
    width: 290px;
    height: 42px;
    color: #000;
    margin-right: 21px;
    display: block;
    position: relative;

    &::after {
        content: "";
        background-image: url(../../src/img/arrow.png);
        background-repeat: no-repeat;
        width: 15px;
        height: 15px;
        position: absolute;
        right: 10px;
        z-index: 1000;
        background-size: 15px;
        top: 15px;
        pointer-events: none;
    }

    ul {
        list-style: none;
        width: 100%;
        border: 1px solid #b4b4b4;
        position: relative;
        z-index: 100;
        background: #fff;

        li.option {
            z-index: 1;
            padding: 5px;
            display: none;
            list-style: none;
            height: 42px;
            line-height: 32px;
            cursor: pointer;
            padding-left: 10px;

            &:first-child {
                display: block;

                &:hover {
                    background: transparent;
                }
            }

            &:hover {
                background-color: #ededed;
            }
        }
    }
}
/* ----------------  3: Navigation ------------------ */

ul.main-nav--inline {
    margin: 0 auto;
    padding: 0;
    list-style: none;
    width: 100%;
    max-width: 1080px;
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    li {
        display: inline-flex;
        padding: 1rem .5rem;
        position: relative;
        justify-content: center;
        align-items: center;
        text-align: center;

        a {
            font-family: $font-source;
            font-size: 16px;
            font-weight: 500;
            text-transform: uppercase;
            text-decoration: none;
            transition: all 0.1s ease-in;

            &:hover {
                color: #999999;
            }

            .current & {
                color: #999999;
            }
        }

        &:first-of-type {
            a {
                padding-left: 0;
            }
        }

        &:nth-last-child(2) {
            a {
                html[lang=da] & {
                    @apply text-green-dark;
                }
            }
        }


        &:last-of-type {
            @apply bg-green-dark text-warm-yellow py-1 px-3 rounded-sm;

            a {
                &.t-1 {
                    background: #92daf6;
                    color: #000;
                    border-radius: 5px;
                    padding-right: 39px;
                    padding-top: 0;
                    padding-bottom: 9px;

                    span {
                        font-size: 11px;
                        width: 100%;
                        display: block;
                        text-align: center;
                        line-height: 0;
                        position: relative;
                        top: -2px;
                    }
                }

                &.t-2 {
                    background: #92daf6;
                    color: #000;
                    border-radius: 5px;
                    padding-right: 39px;
                }
            }

            &:after {
                display: none;
            }
        }
    }
}

.sub-nav {
    background: #ededed;

    .sub-nav--inline {
        display: inline-block;
        list-style: none;

        li {
            display: inline-block;

            a {
                text-decoration: none;
                padding: 15px 15px;
            }
        }
    }
}

@media (max-width: $sm-max) {
    .scroll {
        white-space: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        width: 100%;

        &::-webkit-scrollbar {
            display: none
        }
    }
}
/* Mobile */

.mobile-nav {
    width: 100vw;
    height: 0;
    position: absolute;
    transition: all 0.5s;
    /* transition settings. */
    z-index: 1001;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 0;
    visibility: hidden;
    opacity: 0;

    @media (max-width: $sm) {
        max-width: 100vw;
        right: 0;
    }

    &.open {
        visibility: visible;
        opacity: 1;
        height: 350px;
        transition: all 0.5s;
        /* transition settings. */
    }

    .mobile-nav--container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        background-color: #000;

        ul.mobile-nav--list {
            display: flex;
            flex-grow: 2;
            flex-direction: column;
            list-style: none;
            padding-top: 10px;

            .main-link {
                font-weight: bold;
            }

            li {
                padding: 10px 15px;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 14px;

                &:nth-last-child(2) {
                    a {
                        color: #FFF5C3;
                    }
                }

                &:last-child {
                    a {
                        color: #92DAF6;
                    }
                }

                a {
                    text-decoration: none;
                    color: #fff;
                    font-family: $font-source;
                }

                > ul {
                    display: none;
                    padding-left: 20px;
                }
            }
        }
    }

    .close-mobile-menu {
        background-image: url(../../src/img/close.png);
        background-repeat: no-repeat;
        background-size: 50%;
        background-position: center;
        background-color: #000;
        width: 40px;
        height: 40px;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
    }
}
/* ----------------  3: Modules --------------------- */
/* Slideshow */

section.slider {
    background-color: #fff;
    padding: 60px 0 40px;
    position: relative;
    margin: 0 auto;
    width: 100%;


    .wrapper {
        lost-center: 1093px;
    }

    h2 {
        text-align: center;
        margin-bottom: 20px;
    }

    .slideControls {
        position: absolute;
        width: 100%;
        top: 326.5px;
        left: 0px;
        z-index: 100;

        .slideNext {
            right: -46px;
            position: absolute;
            cursor: pointer;
            z-index: 101;

            img {
                width: 36px;
                height: 48px;
            }

            @media (max-width: 1230px) {
                right: 10px;
                top: -10px;
            }
        }

        .slidePrev {
            left: -53px;
            position: absolute;
            cursor: pointer;
            z-index: 101;

            img {
                width: 36px;
                height: 48px;
            }

            @media (max-width: 1230px) {
                left: 20px;
                top: -10px;
            }
        }
    }

    .lSSlideWrapper {
        width: 1080px;
        //border:1px solid red;
        @media (max-width: $md) {
        }
    }

    .lSSlideOuter {
        + .dms.wrapper {
            padding: 0 30px 0 10px;
        }
    }

    ul.light-slider {
        margin-bottom: 25px;
        height: auto !important;

        .sliderCTA {
            display: flex;
            position: absolute;
            bottom: 170px;
            right: -9px;
            width: 32px;
            z-index: 100;
            max-height: 91px;

            svg {
                display: inline-block;
                width: 100%;
                height: auto;
            }
        }
    }
}

section.slider-mobile {
    background-color: #fff;
    padding: 40px 0 25px;
    position: relative;
    max-width: 1090px;
    margin: 0 auto;


    @media (max-width:330px) {
        padding-top: 0;
    }

    h2 {
        text-align: center;
        margin-bottom: 20px;

        @media (max-width:330px) {
            margin-bottom: 40px;
        }
    }

    .slideControls {
        position: absolute;
        width: 100%;
        top: 326.5px;
        left: 0px;

        .slideNext {
            right: -43px;
            position: absolute;
            cursor: pointer;

            img {
                width: 36px;
                height: 48px;
            }
        }

        .slidePrev {
            left: -53px;
            position: absolute;
            cursor: pointer;

            img {
                width: 36px;
                height: 48px;
            }
        }
    }

    .lSSlideWrapper {
        max-width: 1090px;

        @media (max-width: $md) {
        }
    }

    .lSSlideOuter {
        max-width: 1090px;
        margin: 0 auto 17px;

        @media (max-width: $md) {
            max-height: 100%;
        }

        + .dms.wrapper {
            padding: 0 30px 0 10px;
        }
    }

    ul.slider-mobile-list {
        @media (max-width: 1090px) {
            max-width: 100%;
            padding-left: 20px;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            li.product--item {
                margin-bottom: 25px;
                width: 100%;
                margin-right: 15px;

                @media (max-width:330px) {
                    margin-bottom: 40px !important;
                }
            }

            .sliderCTA {
                position: absolute;
                bottom: 105px;
                right: -9px;
                width: 32px;
                max-height: 91px;
            }
        }

        @media (max-width:1090px) {
            li.product--item {
                position: relative;
                max-width: 347px;
                width: 100%;
                float: none;
                margin-bottom: 25px;
                height: 621px;
            }
        }
    }

    .lightSlider {
        @media (max-width: $md) {
            overflow: visible;
        }
    }
}

.loan-container {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 10px;

    @media (max-width: $md-max) {
        padding: 10px 10px 20px;
    }

    .loan-slider-wrapper {

        .hero-unit-loan & {
            margin-bottom: 25px;
        }

        @media (max-width: $sm) {
            margin-bottom: 0;
        }

        .ui-slider-range {
            background-color: #4196ba;
        }
    }

    .loan-center--pligt {
        p {
            font-size: 12px;
            color: #333333;
            font-family: $font-source;
        }
    }

    .loan-center--cta {
        p {
            font-size: 12px;
            color: #333333;
            font-family: $font-source;
        }
    }

    .loan-center--payment {
        padding: 20px 15px 5px !important;

        .product--price-box--price {
            font-size: 50px;
            font-family: $font-source;
            margin-top: 10px;
            margin-bottom: 10px;

            @media (max-width: $sm) {
                margin-top: 0;
            }
        }
    }
}
/* Price box */

.product--price-box {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
    color: #fff;
    padding: 20px 20px 5px;
    margin: auto 0;

    @media (max-width: $md) {
        left: 0;
        right: 0;
        margin: 0 auto;
        width: auto;
    }

    .product--price-box--finance {
        font-family: $font-source;
        font-size: 13px;
    }

    .product--price-box--price {
        color: #FFF5C3;
        font-family: $font-roboto;
        font-size: 35px;
    }

    .product--price-box--delivery {
        font-family: $font-source;
        font-size: 13px;
    }

    .product--price-box--cash {
        font-family: $font-source;
        font-size: 18px;

        .product-details & {
            font-size: 13px;
        }
    }

    .product--img {
        @media (max-width: $md) {
            margin-bottom: 60px;
        }

        img {
            max-width: 566px;
            position: absolute;
            bottom: 0;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 0;

            @media (max-width: $md) {
                max-width: 500px;
            }
        }
    }

    &.no-bg {
        background-color: rgba(0, 0, 0, 0);
        position: relative;
        color: #000;

        .product--price-box--price {
            color: #000;
        }

        .hero-unit-product--text & {
            float: left;
            margin-left: 15px;
            margin-top: 0;
            margin-bottom: 10px;

            @media (max-width:1007px) {
                margin-left: 0;
            }
        }

        .specs-price & {
            margin-top: 0;
            margin-right: 0;
            padding-right: 0;
            padding-top: 0;
        }

        .loan-price-box & {
            padding-top: 0;
            margin-top: 0;
        }

        .order-box-price & {
            margin-top: 0;
        }
    }
}
/* Pligt tekst */

.pligt {
    position: relative;

    .no-flexboxlegacy .half-n-half & {
        bottom: 50px !important;
    }

    .half-n-half & {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0 10px 10px;

        @media (max-width: $md) {
            position: relative;
        }
    }

    .product--item-inner & {
        padding: 5px;
    }

    .hero-unit & {
        bottom: 5px;
        padding: 0 10px;
        margin-top: 10px;
        width: 100% !important;

        @media (max-width: 991px) {
            display: none;
        }
    }

    .hero-unit-product--text & {
        @media (min-width: 992px) {
            display: none;
        }

        @media (max-width: 991px) {
            display: block;
        }
    }

    .specs-price & {
        margin-top: 15px;
        padding: 0;
        width: 100% !important;
    }

    .loan-price-box & {
        margin-top: 15px;
        padding: 0;
        width: 100% !important;
    }
}
/* Contact */

div.contact {
    @media (min-width: 1090px) {
        width: 50%;
    }

    float: left;
    padding-top: 5px;
    -js-display: flex;
    display: flex;
    justify-content: center;

    .icon--phone {
        position: relative;
        float: left;
        margin-right: 15px;
        width: 28px;
        height: 28px;

        @media (max-width: $md-max) {
            margin: 0;
        }
    }

    font-size: 25px;

    .contact-text {
        float: left;
        margin-bottom: 15px;
    }

    a.contact--number {
        font-weight: bold;
        text-decoration: none;
    }

    @media (max-width: $md-max) {
        width: auto;
        padding-top: 0;
    }
}
/* Customer Center */

.customer-center-bg {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    position: fixed;
    opacity: 0;
    transition: opacity .3s;
    top: 0;
    left: 0;
    display: none;

    &.open {
        display: block;
        opacity: 1;
    }
}

.blocker {
    z-index: 2000;
}

section.customer-center {
    width: 100%;
    height: 100%;
    max-width: 680px;
    position: fixed;
    top: 0;
    right: 0;
    transform: translate3d(100vw, 0, 0);
    transition: transform 0.5s;
    /* transition settings. */
    z-index: 1001;
    overflow-y: auto;
    overflow-x: hidden;

    @media (max-width: $sm) {
        max-width: 100vw;
        right: 0;
    }



    .customer-center-inner {
        background: #323232;
        height: 100%;
        position: absolute;
        top: 0;
        left: 40px;
        max-width: 640px;
        width: 100%;

        @media (max-width: $sm-max) {
            width: calc(100% - 40px);
        }

        input {
            &::-webkit-input-placeholder {
                font-size: 14px;
            }
        }
    }

    &.open {
        transform: translate3d(0, 0, 0);
        transition: transform 0.5s;
        /* transition settings. */
    }

    .header-bg {
        background-color: #e4184b;
        color: #fff;
        text-align: center;
        height: 100px;

        h2 {
            line-height: 100px;

            @media (max-width: $sm) {
                line-height: 50px;
            }
        }
    }

    .chat,
    .faq,
    .call-me,
    .bottom-links {
        padding: 40px 43px;

        @media (max-width: $sm) {
            padding: 40px 15px;
        }
    }

    .chat {
        background: #fff;

        input.chat-input {
            width: 100%;
            max-width: 265px;
            float: left;
            margin-right: 20px;
            color: #000000;
            height: 41px;

            @media (max-width: $md) {
                max-width: 100%;
                margin-bottom: 10px;
            }
        }

        h2 {
            position: relative;

            &:before {
                content: "";
                display: inline-block;
                background: url(../../src/img/svg/chat.svg);
                background-size: 100%;
                width: 33px;
                height: 25px;
                margin-right: 10px;

                @media (max-width: $sm) {
                    position: absolute;
                    left: 5px;
                    top: 7px;
                    margin: 0;
                }

                @media (max-width:330px) {
                    display: none;
                }
            }
        }

        a.btn.cta-green {
            float: right;
            width: 100%;
            max-width: 265px;

            @media (max-width: $md) {
                max-width: 100%;
            }
        }

        p.center-text {
            margin-bottom: 36px;
        }
    }

    .faq {
        background: #ededed;
        padding-bottom: 0;

        h2 {
            position: relative;

            &:before {
                position: relative;
                top: 3px;
                content: "";
                display: inline-block;
                background: url(../../src/img/svg/faq2.svg);
                background-size: 100%;
                width: 30px;
                height: 30px;
                margin-right: 10px;

                @media (max-width: $sm) {
                    position: absolute;
                    left: 15px;
                    top: 7px;
                }

                @media (max-width:330px) {
                    display: none;
                }
            }
        }

        p {
            margin-bottom: 30px;
        }

        .faq-icons {
            a {
                text-decoration: none;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                background: #fff;
                border-radius: 50%;
                width: 100px;
                height: 100px;

                svg {
                    width: 50px;
                    height: 46px;

                    &:hover {
                        fill: #999999;
                    }
                }

                &:last-of-type {
                    margin-right: 0;
                }

                @media (max-width: $md) {
                    margin: 0;
                    margin-bottom: 0;

                    h5 {
                        font-size: 14px;
                    }
                }
            }

            text-align: center;
            padding-bottom: 30px;

            @media screen {
                -js-display: flex;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                flex-wrap: wrap;
            }

            @media(max-width:330px) {
                flex-wrap: nowrap;
            }
        }

        .drawer {
            transition: max-height 0.5s;
            height: 0;
            max-height: 0;
            display: none;
            visibility: hidden;

            &.open {
                display: block;
                max-height: 100%;
                height: 100%;
                transition: max-height 0.5s;
                visibility: visible;
                padding: 30px 0;
            }

            .drawer-close {
                cursor: pointer;
                width: 50px;
                height: auto;
            }
        }
    }

    .call-me {
        background: #d9d9d9;

        .contact {
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            text-align: center;
            margin-bottom: 30px;

            .contact--text {
                margin-bottom: 25px;

                .contact--number {
                    &:before {
                        position: relative;
                        top: 0;
                        content: "";
                        display: inline-block;
                        background: url(../../src/img/svg/tlf_46x46_ps.svg);
                        background-size: auto auto;
                        background-size: 100%;
                        width: 26px;
                        height: 26px;
                        margin-right: 10px;
                    }
                }

                .icon--phone {
                    margin-top: 5px;
                }
            }
        }

        .contact--form {
            list-style: none;

            li {
                margin-bottom: 10px;
                width: 100%;

                @media (max-width: $md) {
                    margin-bottom: 0;
                }

                &:first-of-type {
                    input {
                        &:last-of-type {
                            margin-right: 0;
                            float: right;
                        }
                    }
                }

                input, select {
                    height: 41px;
                }

                input:-ms-input-placeholder {
                    color: #000 !important;
                    font-size: 14px;
                    visibility: visible;
                }

                button.btn {
                    position: relative;
                    width: 265px;
                    padding: 10px 33px;
                    float: right;
                    max-height: 41px;

                    @media (max-width: $md) {
                        width: 100%;
                    }
                }
            }
        }
    }

    .bottom-links {
        background: #323232;

        ul {
            list-style: none;

            li {
                a {
                    color: #fff;
                    text-decoration: none;

                    span {
                        font-size: 14px;
                        font-stretch: condensed;
                    }
                }
            }
        }
    }
}

.customer-center-btn {
    position: fixed;
    right: 15px;
    bottom: 0;
    z-index: 100;
    visibility: visible;

    a {
        background-color: #E4184B;
        padding: 10px;
        -js-display: flex;
        display: flex;
        font-weight: bold;
        text-transform: uppercase;
        color: #fff;
        font-family: $font-source;
        text-decoration: none;

        span {
            align-items: center;
        }

        svg {
            height: 20px;
            margin-right: 5px;
            max-width: 30px;
            fill: #fff;
        }
    }

    @media (max-width: $md) {
        transform: rotate(90deg);
        bottom: 57px;
        right: -58px;
    }
}
/* Loan Center */

section.loan-center {
    width: 100%;
    height: 100%;
    max-width: 680px;
    position: fixed;
    top: 0;
    right: 0;
    transform: translate3d(100vw, 0, 0);
    transition: transform 0.5s;
    /* transition settings. */
    z-index: 1001;
    overflow-y: auto;


    .loan-center-inner {
        background: #323232;
        height: 100%;
        position: absolute;
        top: 0;
        left: 40px;
        padding-bottom: 25px;

        .loan-center--header {
            background-color: #fff;
            color: #000;
            text-align: center;
        }

        .loan-center--slider {
            padding-bottom: 0;
            padding-top: 25px;

            p {
                margin-bottom: 30px;
            }
        }

        .loan-price-box {
            padding: 0 50px 50px;
            text-align: center;

            a.btn.cta-green {
                display: inline-block;
                margin: 30px auto 0;
                max-width: 247px;

                @media (max-width: $md) {
                    max-width: 100%;
                }
            }

            p.center-text {
                margin-bottom: 36px;
            }
        }

        .loan-center--checks {
            padding-left: 40px;
            padding-right: 70px;
            padding-top: 45px;
            padding-bottom: 100px;

            span {
                float: left;
                margin-right: 20px;
                margin-bottom: 20px;
                height: 100%;
            }

            ul {
                list-style: none;

                li {
                    display: inline-block;

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    .checkmark-container {
                        float: left;
                        margin-right: 10px;
                    }

                    .loan-center--text-container {
                        float: left;
                        width: 93%;
                        margin-bottom: 35px;
                    }

                    svg.checkmark {
                        float: left;
                        margin-right: 7px;
                        margin-top: 3px;
                        width: 16px;
                        height: 17px;
                        fill: rgb(60,165,75);
                    }
                }
            }
        }
    }

    &.open {
        transform: translate3d(0, 0, 0);
        transition: transform 0.5s;
        /* transition settings. */
    }

    .loan-center--header,
    .call-me,
    .bottom-links {
        padding: 40px 43px;
    }

    .faq {
        background: #ededed;
        padding-bottom: 0;

        p {
            margin-bottom: 30px;
        }

        .faq-icons {
            a {
                text-decoration: none;
                margin-right: 65px;

                &:last-of-type {
                    margin-right: 0;
                }

                @media (max-width: $md) {
                    margin: 0;
                    margin-bottom: 30px;
                    width: 50%;
                }
            }

            text-align: center;
            margin-bottom: 30px;

            @media screen {
                -js-display: flex;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: wrap;

                @media(max-width:330px) {
                    flex-wrap: nowrap;
                }
            }
        }

        .drawer {
            transition: max-height 0.5s;
            height: 0;
            max-height: 0;
            display: none;
            visibility: hidden;

            &.open {
                display: block;
                max-height: 100vh;
                height: 100%;
                transition: max-height 0.5s;
                visibility: visible;
                padding-bottom: 30px;
            }

            .drawer-close {
                cursor: pointer;
                width: 50px;
                height: auto;
            }
        }
    }

    .call-me {
        background: #d9d9d9;

        .contact {
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            text-align: center;
            margin-bottom: 30px;

            .contact--text {
                .icon--phone {
                    @media (max-width: $md) {
                        margin-right: 9px;
                        margin-top: 9px;
                        max-width: 15px;
                        height: auto;
                    }
                }
            }
        }

        .contact--form {
            list-style: none;

            li {
                margin-bottom: 10px;
                width: 100%;

                @media (max-width: $md) {
                    margin-bottom: 0;
                }

                &:first-of-type {
                    input {
                        &:last-of-type {
                            margin-right: 0;
                        }
                    }
                }

                a.btn {
                    position: relative;
                    top: 2px;
                    width: 265px;
                    padding: 10.5px 33px;

                    @media (max-width: $md) {
                        width: 100%;
                    }
                }
            }
        }
    }

    .bottom-links {
        background: #323232;

        ul {
            list-style: none;

            li {
                a {
                    color: #fff;
                    text-decoration: none;

                    span {
                        font-size: 14px;
                        font-stretch: condensed;
                    }
                }
            }
        }
    }
}
/* Loan slider */

.loan-slider-container {

    p.loanBasket {
        width: 50%;
        float: left;
        text-align: left;

        &:last-of-type {
            text-align: right;
        }
    }

    .amount-min {
        width: 50%;
        float: left;
        text-align: left;
    }

    .amount-max {
        width: 50%;
        float: left;
        text-align: right;
    }

    .months-min {
        width: 50%;
        float: left;
        text-align: left;
    }

    .months-max {
        width: 50%;
        float: left;
        text-align: right;
    }

    .ui-slider-tooltip {
        position: absolute;
        top: -35px;
        left: -21px;
        height: 25px;
        min-width: 80px;
        padding-top: 2px;
        background: rgb(255, 228, 99);
        background: -moz-linear-gradient(top, rgba(255, 228, 99, 1) 0%, rgba(255, 241, 176, 1) 100%);
        background: -webkit-linear-gradient(top, rgba(255, 228, 99, 1) 0%, rgba(255, 241, 176, 1) 100%);
        background: linear-gradient(to bottom, rgba(255, 228, 99, 1) 0%, rgba(255, 241, 176, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffe463", endColorstr="#fff1b0", GradientType=0);
        border: 1px solid #bbad6c;
        text-align: center;
        color: #000;

        .hero-unit-loan & {
            background: none;
            background-color: #4196ba;
            color: #fff;
            padding-top: 2px;
            border: 1px solid #fff;

            &:before {
                border-top-color: #fff;
            }

            &:after {
                border-top-color: #4196ba;
            }
        }

        .loan-basket-info & {
            background: none;
            background-color: #4196ba;
            color: #fff;
            padding-top: 2px;
            border: 1px solid #fff;

            &:before {
                border-top-color: #fff;
            }

            &:after {
                border-top-color: #4196ba;
            }
        }

        .stand-alone-loan-slider & {
            background: none;
            background-color: #4196ba;
            color: #fff;
            padding-top: 2px;
            border: 1px solid #fff;

            &:before {
                border-top-color: #fff;
            }

            &:after {
                border-top-color: #4196ba;
            }
        }
    }

    .ui-slider-handle {
        cursor: pointer;
    }


    .ui-slider-tooltip:after, .ui-slider-tooltip:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    .ui-slider-tooltip:after {
        border-color: rgba(255, 241, 176, 0);
        border-top-color: #fff1b0;
        border-width: 10px;
        margin-left: -10px;
    }

    .ui-slider-tooltip:before {
        border-color: rgba(187, 173, 108, 0);
        border-top-color: #bbad6c;
        border-width: 11px;
        margin-left: -11px;
    }

    .ui-slider-horizontal.ui-slider-handle {
        top: 0.6-em !important;
    }

    .ui-state-default,
    .ui-widget-content .ui-state-default,
    .ui-widget-header .ui-state-default,
    .ui-button,
    html .ui-button.ui-state-disabled:hover,
    html .ui-button.ui-state-disabled:active {
        border: none;
        background: transparent;
    }

    .ui-state-active,
    .ui-widget-content .ui-state-active,
    .ui-widget-header .ui-state-active,
    a.ui-button:active,
    .ui-button:active,
    .ui-button.ui-state-active:hover {
        border: none !important;
        background: none !important;
    }

    .loan-slider-amount,
    .loan-slider-runtime {
        width: 100%;
        margin: 50px 0 20px;

        .ui-slider-handle {
            top: -.7em;
        }
    }

    .loan-slider-amount:focus {
        outline: none;
    }
}

.ui-slider-horizontal .ui-slider-handle {
    border: none !important;
    top: -.4em;
}
/* Filter */

section.product-form-selection {
    background-color: #fff;
    padding-bottom: 40px;

    @media (max-width: $md) {
        display: none;
    }

    .padding-box {
        padding: 10px 10px;
        background: #ededed;
        height: 145px;
        width: 99%;

        > div {
            width: 33.3%;
            padding: 10px 2%;
            float: left;
            background: #fff;
            height: 125px;
            border-right: 1px solid #ededed;

            @media (max-width: 1040px) {
                h3 {
                    font-size: 16px;
                }

                .selector {
                    width: 100%;
                }
            }

            &:last-of-type {
                border-right: 0;
            }

            h3 {
                margin-bottom: 24px;
            }
        }
    }

    .price-slide-container {
        position: relative;

        input {
            margin: 0;
            padding: 0;
            font-size: 15px;
            max-width: 130px;
            width: 100%;
            display: inline-block;
            float: left;
            border: 0;

            &#amount-2 {
                max-width: 117px;
                width: 100%;
                float: right;
            }
        }

        a {
            text-decoration: underline;
            position: absolute;
            right: 10px;
            top: 0;
            display: inline-block;
            font-size: 13px;
            font-family: $font-source;
        }
    }

    .brand-choice-container {
        select {
            width: 100%;
        }
    }

    .screen-size-container {
        select {
            width: 100%;
        }
    }
}
/*Compare */
.compare {
    position: absolute;
    background: #ffd400;
    width: 50%;
    padding: 15px;
    z-index: 200;
    display: flex;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: all .3s ease-in-out;
    right: 10px;

    @media (max-width:1159px) {
        display: none;
    }

    .slider & {
        display: none;
    }

    p {
        text-transform: uppercase;
        font-weight: 700;
        font-family: $font-roboto;
    }

    li.product--item:hover & {
        visibility: visible;
        opacity: 1;
    }

    input {
        float: left;
        margin-right: 10px;
        -webkit-appearance: none;
        border: 1px solid #000;
        color: #000;
        position: relative;
        width: 18px;
        height: 18px;
        padding: 10px;

        &:checked {
            &:after {
                content: "x";
                position: absolute;
                margin: 0 auto;
                color: #000;
                top: 0px;
                left: 4px;
                font-size: 18px;
                text-transform: uppercase;
            }
        }
    }
}

.compare-container {
    @media (max-width:1159px) {
        display: none;
    }

    z-index: 999;
    background: #ffd400;
    background-image: url(../../src/img/yellow-bg.png);
    background-size: cover;
    position: fixed;
    right: 0;
    min-height: 250px;
    top: 50vh;
    border-radius: 15px 0 0 15px;
    padding: 15px 20px;

    h3 {
        margin-bottom: 15px;
    }

    ul {
        padding-left: 0;

        li {
            list-style: none;
        }
    }

    a.btn {
        position: absolute;
        bottom: 15px;
    }
}
/* Product Listing */

section.product-listing {
    background-color: #fff;

    .wrapper {
        max-width: 1080px;
    }

    .product-listing--sort {
        position: relative;

        h5 {
            text-align: center;

            @media (max-width: $md) {
                text-align: left;
                width: 35%;
                float: left;
            }

            @media (max-width: $sm) {
                text-align: center;
                width: 100%;
                float: none;
            }
        }

        select#sort-by {
            position: absolute;
            right: 6px;
            border: none;
            width: 135px;
            font-size: 13px;
            font-family: $font-source;
            margin-right: 6px;
            top: -8px;

            @media (max-width: $md) {
                position: relative;
                float: right;
                width: 130px !important;
            }
        }
    }

    ul.product {
        margin-top: 50px;
        list-style: none;
        width: 100%;
        max-width: 1080px;

        li.product--item {
            margin-bottom: 50px;
            z-index: 5;

            .sliderCTA {
                display: flex;
                position: absolute;
                bottom: 170px;
                right: -9px;
                width: 32px;
                z-index: 100;
                max-height: 91px;

                svg {
                    display: inline-block;
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}

a:-webkit-any-link {
    text-decoration: none !important;
}

.lightSlider.lsGrab > * {
    cursor: pointer;
}
/* Product item / Slider item */


li.product--item {

    .product--item--wrapper {
        max-width: 330px;
        width: 100%;
        position: relative;

        @media screen and (max-width:415px) {
            max-width: 360px;
        }
    }

    span.product--price-box--splash {
        position: absolute;
        top: -20px;
        left: -17px;
        padding: 5px 10px;
        text-transform: uppercase;
        font-family: $font-roboto;

        &.yellow {
            background-color: #ffd400;
            color: #000;

            &:before {
                content: url('/Leasy.Assets/src/img/svg/corner_left_yellow.png');
                height: auto;
                position: absolute;
                top: 100%;
                left: 0;
                z-index: -1;
            }

            &:after {
                content: url(/Leasy.Assets/src/img/svg/corner_right_yellow.png);
                height: 30px;
                overflow: hidden;
                position: absolute;
                left: 100%;
                top: 0px;
                z-index: -1;
            }
        }

        &.red {
            background-color: #e2184a;
            color: #fff;

            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }

            &:before {
                content: url('/Leasy.Assets/src/img/svg/corner_left_red.png');
                height: auto;
                position: absolute;
                top: 100%;
                left: 0;
                z-index: -1;
            }

            &:after {
                content: url(/Leasy.Assets/src/img/svg/corner_right_red.png);
                height: 30px;
                overflow: hidden;
                position: absolute;
                left: 100%;
                top: 1px;
                z-index: -1;
            }
        }

        &.grey {
            background-color: #4b4b4b;
            color: #fff;

            &:before {
                content: url('/Leasy.Assets/src/img/svg/corner_left_black.png');
                height: auto;
                position: absolute;
                top: 100%;
                left: 0;
                z-index: -1;
            }

            &:after {
                content: url(/Leasy.Assets/src/img/svg/corner_right_black.png);
                height: 30px;
                overflow: hidden;
                position: absolute;
                left: 100%;
                top: 1px;
                z-index: -1;
            }
        }

        &.black {
            background-color: #000;
            color: #fff;

            &:before {
                content: url('/Leasy.Assets/src/img/svg/corner_left_black.png');
                height: auto;
                position: absolute;
                top: 100%;
                left: 0;
                z-index: -1;
            }

            &:after {
                content: url(/Leasy.Assets/src/img/svg/corner_right_black.png);
                height: 30px;
                overflow: hidden;
                position: absolute;
                left: 100%;
                top: 1px;
                z-index: -1;
            }
        }
    }

    .product--item-link {
        border: 1px solid #ccc;
        display: flex;
        flex-direction: column;

        @media (max-width:$sm) {
            height: auto;
        }
    }

    .light-slider & {
        //float: left; // just to make it float, when light slider hasn't been initialized
    }

    .center {
        text-align: center;
        position: relative;
    }

    .product--item-inner {
        background-color: #fff;
        padding: 15px 0;
        padding-bottom: 0;
        margin-bottom: 26px;
        flex-grow: 1;

        .rateit {
            margin-bottom: 15px;
        }

        .product--details {
            @media(max-width: $sm) {
                padding: 0 15px;
            }
        }
    }

    .product--price-wrapper {
        position: relative;
        min-height: 223px;

        &.ad--overlay {
            position: absolute;
            top: 405px;
            height: 223px;
            background: transparent !important;

            &.text-white {
                color: #fff;

                .product--price-box {
                    color: #fff;
                }

                .product--price-box--price {
                    color: #fff;
                }
            }
        }

        .pligt {
            padding: 0 5px 5px;
        }
    }

    .product--details {
        .img-center {
            padding: 0 30px;
        }
    }

    .img--center {
        position: relative;
        min-height: 143px;
        display: block;
        margin-bottom: 25px;

        .product--img {
            position: absolute;
            display: block;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
    }

    .productImg {
        display: block;
        height: 675px;
    }

    .product--rating {
        margin: 20px 0 10px;
    }

    h4 {
        text-align: center;
        padding: 0 10px;
        margin-top: 15px;
        font-size: 22px;
        line-height: auto;
    }

    ul.specs {
        margin-top: 15px;
        padding-left: 44px;

        @media (max-width: 1024px) {
            margin-bottom: 15px;
        }

        li {
            list-style: disc;
            text-align: left;
            font-family: $font-source;
            font-size: 15px;
        }
    }
}
/* Product item TABS */

ul.tab {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;

    li {
        float: left;

        a {
            display: inline-block;
            color: black;
            text-align: center;
            padding: 14px 16px;
            text-decoration: none;
            transition: 0.3s;
            font-size: 17px;
            border: 1px solid #f1f1f1;
            border-bottom: none;

            &:hover {
                background-color: #f1f1f1;
            }

            &:focus {
                background-color: #f1f1f1;
            }

            &.active {
                background-color: #f1f1f1;
            }

            @media (max-width: 320px) {
                padding: 14px 6px;
            }
        }
    }
}

#modal-specs {
    #tab-specs {
        ul {
            li {
                display: flex;
                background: #d1d1d1;

                span {
                    &:first-of-type {
                        width: 100%;
                        margin-right: 10px;
                    }
                }

                &:nth-child(odd) {
                    background: #fff;
                }
            }
        }
    }
}

#tab-specs {
    ul {
        list-style: none;
        -js-display: flex;
        display: flex;
        flex-direction: column;
        align-items: center;

        li {
            padding: 5px;
            font-family: $font-source;
            width: 100%;
            display: flex;

            &:nth-child(2n+1) {
                background: #fff;
            }

            span {
                display: inline-block;
                width: 50%;
                text-align: right;

                &:first-of-type {
                    padding-right: 12px;
                    text-align: left;
                }

                @media (max-width: $md) {
                    width: auto;
                }
            }
        }
    }
}

.tabcontent {
    padding: 25px 12px 50px;
    border-top: none;
    -webkit-animation: fadeEffect 1s;
    animation: fadeEffect 1s;
    /* Fading effect takes 1 second */
    .wrapper {
        background: transparent;

        .col-half {
            &:last-of-type {
                margin-left: 2rem;

                @media (max-width: $md) {
                    margin-top: 15px;
                    margin-left: 0;
                }
            }
        }
    }
}

@-webkit-keyframes fadeEffect {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeEffect {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.close-modal {
    background-image: url(../../src/img/close.png);
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: center;
    background-color: #000;
    width: 40px;
    height: 40px;
    position: absolute;
    position: sticky;
    top: 0;
    left: 0;
    cursor: pointer;
}
/* ----------------  4: Header--------------------- */

header {
    @apply bg-green-dark;
    color: #FFF5C3;

    div.quick-icons {
        padding-top: 10px;
        width: 25%;
        float: left;
        margin-right: 0;
        text-align: right;

        :first-child {
            margin-right: 24px;
        }
    }

    .search {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        max-width: 540px;
        width: 100%;
        top: 25px;
        z-index: 500;
        height: 40px;
        visibility: hidden;
        opacity: 0;
        transition: all .3s ease-in;

        &.open {
            visibility: visible;
            opacity: 1;
            transition: all .3s ease-in-out;
        }

        @media (max-width: $sm) {
            width: 99%;
            top: 20px;
            height: 48px;
        }

        form {
            display: flex;
            align-items: center;
            background: #fff;
            border-radius: 5px;
            width: 100%;
            height: 100%;
            position: relative;
            padding: 0 10px;

            input.search-input {
                border: 1px solid #fff;
                left: 0;
                width: 100%;
                height: 100%;
                margin: 0;
                font-size: 16px;
                -webkit-appearance: none;

                &::placeholder {
                    color: #000;
                    font-size: 16px;
                    top: 3px;
                }

                &:-ms-input-placeholder {
                    color: #000;
                    font-size: 16px;
                    line-height: 1;
                    top: 0;
                }
            }

            .search-btn {
                border: none;
                padding: 0;
                margin: 0;
                margin-right: 20px;
                position: absolute;
                right: 25px;
                top: 10px;
                background: none;

                svg {
                    height: 23px;
                    width: 23px;
                }
            }

            .close-search {
                cursor: pointer;
                position: absolute;
                right: 15px;
                font-size: 20px;
                background: #000;
                color: #fff;
                border-radius: 50%;
                height: 15px;
                width: 15px;
                text-align: center;
                line-height: 15px;
                padding: 0 0 0 1px;
                top: 13px;
            }
        }
    }

    svg.icon--search {
        display: inline-block;
        height: 23px;
        max-width: 30px;
        cursor: pointer;
    }

    svg.icon--menu {
        max-width: 30px;
        height: 23px;
    }

    svg.icon--logo {
        width: 127px;
        height: 43px;
    }

    .mini-basket {
        position: relative;
        display: inline-block;
        text-align: center;

        svg.icon--basket {
            display: inline-block;
            height: 23px;
            max-width: 30px;
            margin: 0;
        }

        .basket-number {
            margin-right: 0 !important;
            position: absolute;
            top: -5px;
            right: -7px;
            background-color: #e4184b;
            border-radius: 50%;
            width: 17px;
            height: 17px;
            font-size: 13px;
            color: #fff;
        }
    }

    .header--top {
        width: 100%;
        max-width: 1080px;
        margin: 0 auto;
        height: 95px;
        padding: 25px 0;

        @media (max-width: 1080px) {
            padding: 25px 20px;
        }

        @media (min-width:$md) and (max-width: 1150px) {
            -js-display: flex;
            display: flex;
            justify-content: space-around;
        }

        div.logo {
            @media (min-width:$lg) {
                width: 25%;
            }

            float: left;
            margin-left: 0;
            text-align: left;
        }

        .icon--phone {
            position: relative;
            top: 5px;
            max-width: 30px;
            height: 23px;
        }
    }

    .header--top-mobile {
        padding: 20px 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        .menu {
            display: inline-block;
            max-width: 30px;
            padding-top: 5px;
        }
    }

    .header--bottom {
        background-color: #000;
        height: auto;
        max-height: 53px;
        width: 100%;
        display: flex;
        align-items: center;
    }
}
/* ----------------  5: Footer --------------------- */

footer {
    background: #fff;
    margin-top: -5px;
    z-index: 1;
    position: relative;
}

.quick-links {
    display: flex;
    flex-direction: column;
    padding: 4rem 0 0;
    background-color: #000;
    text-align: center;

    @media (max-width: $md) {
        padding: 60px 10px 30px;
    }



    .link-container {
        width: 100%;
        max-width: 1080px;
        margin: 0 auto;
        width: 100%;
        text-align: center;
        height: auto;


        @media (max-width: $md) {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-bottom: -65px;
            height: 100%;
            padding-left: 20px;
        }

        > li {
            display: none;
        }
    }

    ul {
        min-height: 140px;
        text-align: left;
        list-style: none;
        display: block;
        float: left;
        margin-right: 55px;

        @media (max-width: $md) {
            margin-right: 0;
            margin-bottom: 65px;
            width: 33%;
        }

        @media (max-width: 640px) {
            margin-right: 0;
            margin-bottom: 20px;
            width: 50%;
        }

        &:last-of-type {
            margin-right: 0;
        }

        li {
            a {
                color: #fff;
                display: inline-block;
                margin: 0 0 10px;
                text-decoration: none;
                font-family: $font-source;
                font-size: 13px;
                line-height: 16px;
            }
        }

        &.level1 {
            > li {
                a {
                    font-weight: 600;
                }
            }
        }
    }

    .social-icons {
        margin-top: 35px;

        a {
            margin-right: 55px;

            &:last-of-type {
                margin-right: 0;
            }
        }

        svg {
            fill: #fff;
            width: 57px;
            height: 62px;
        }
    }
}

.bottom-info {
    padding: 30px 0;

    @media (max-width: $md) {
        padding: 30px 40px;
    }

    @media (max-width: $sm) {
        padding: 30px 15px;
    }

    .contact {
        width: 100%;
        margin-bottom: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: $md) {
            -js-display: flex;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .icon--phone {
            @media (max-width: $md) {
                margin-bottom: 10px;
            }
        }
    }

    .copyright {
        @media (max-width: $sm) {
            padding: 0 45px;
        }
    }
}
/* ----------------  6: Content --------------------- */

section.search.product-listing {
    padding: 45px 0;

    h1 {
        margin-bottom: 35px;
    }
}



section.hero-unit {
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-bottom: -2px;

    @media (max-width: $md-max) {
        height: auto;
        background-image: none;
    }

    &.one-column {
        .hero-unit-wrapper {
            .hero-unit-product {
                width: 100%;

                img {
                    max-height: 300px;
                    padding-bottom: 15px;
                }
            }
        }
    }

    .hero-unit-wrapper {
        width: 100%;
        max-width: 1080px;
        margin: 0 auto;
        padding: 25px 0 0;

        @media (max-width: $md-max) {
            display: flex;
            flex-direction: column;
            flex-grow: stretch;
            padding-bottom: 0;
            padding-top: 35px;
        }

        &.white {
            color: #fff;

            .hero-unit-product--text {
                @media (max-width: $md-max) {
                    background: #333;
                }
            }
        }

        > div {
            width: 50%;
            background: transparent;
        }

        .hero-unit-product--text {
            top: 9px;
            width: 50%;
            lost-column: 1/2 2 15px;
            text-align: center;
            position: relative;

            @media (max-width: $md-max) {
                lost-column: 1/1;
                order: 2;
                background: #fff;
                padding-top: 18px;
                padding-bottom: 17px;
            }

            @media (max-width: 1040px) {
                padding-left: 15px;
                padding-right: 15px;
            }

            .hero-unit-product--text--title {
                font-family: $font-roboto;
                font-size: 50px;
                font-weight: bold;
                text-align: center;
                line-height: 52px;
                margin-bottom: 30px;
            }

            .hero-unit-product--text--teaser {
                font-family: $font-source;
                font-size: 18px;
                line-height: 26px;
            }

            .product--price-box {
                width: auto;

                @media (max-width: $md-max) {
                    float: none;
                    margin: 0 auto;
                    margin-bottom: 15px;
                }

                &.white {
                    color: #fff !important;
                }

                .product--price-box--price.white {
                    color: #fff;
                }
            }

            a.btn {
                @media (max-width: $md-max) {
                    margin-top: 17px;
                }
            }
        }

        .hero-unit-product {
            width: 50%;
            max-height: 320px;
            float: left;
            lost-column: 1/2;
            position: relative;
            text-align: center;

            @media (max-width: $md-max) {
                lost-column: 1/1;
                order: 1;
                max-height: 100%;
            }

            a.banner-img-link {
                display: block;
            }
        }
    }
}



section.hero-unit-loan {
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 0 25px;

    @media (max-width: $md) {
        padding: 40px 0 25px;
    }

    h1 {
        color: #fff;
        margin-bottom: 30px;
        font-size: 70px;
        line-height: 70px;

        @media (max-width: $md) {
            margin-bottom: 20px;
            font-size: 30px;
            line-height: 35px;
            padding: 0 20px;
        }
    }

    h2 {
        color: #fff;
        font-family: $font-source;
        font-weight: 300;
        font-size: 55px;
    }

    .loan-steps {
        @media (max-width: $md) {
            display: none;
        }

        ul.loan-steps-list {
            list-style: none;
            margin-bottom: 35px;

            li {
                lost-column: 1/3;
                display: inline-block;
                text-align: center;
                -js-display: flex;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;

                span {
                    float: left;
                    margin-right: 10px;
                    border: 1px solid #fff;
                    border-radius: 50%;
                    font-size: 45px;
                    display: block;
                    width: 55px;
                    height: 55px;
                    text-align: center;
                }

                p {
                    float: left;
                }
            }
        }
    }
}

section.hero-unit--about {
    margin-bottom: -5px;
    margin-top: -1px;

    img {
        width: 100%;
        object-fit: cover;
    }
}

section.why {
    background: #fff !important;
    padding: 65px 0 75px;

    .wrapper {
        > div {
            lost-column: 1/3;
            text-align: center;

            @media (max-width: $md) {
                lost-column: 1/1;
                margin-bottom: 25px;
            }

            h3 {
                margin-bottom: 15px;
            }

            img {
                display: inline-block;
                margin-bottom: 20px;
                height: 60px;
            }
        }
    }

    svg {
        max-width: 66px;
        max-height: 60px;
        height: 100%;
        width: 100%;
        fill: #4A4A4A;
        margin-bottom: 20px;
    }
}

section.advice {
    background: #fff;
    padding: 65px 0 45px;

    h1 {
        margin-bottom: 55px;
        color: #4196ba;
    }

    .advice-container {
        margin-bottom: 30px;
        lost-column: 1/2;
        display: flex;

        @media(max-width: $md) {
            lost-column: 1/1;
        }

        .advice--icon {
            max-width: 68px;
            width: 100%;
            float: left;
            margin-right: 20px;

            svg {
                fill: #4196BA;
                width: 67px;
                height: 67px;
            }

            @media (max-width: $md) {
                float: left;
                margin-right: 10px;
            }
        }

        .advice--text {
            width: 83%;
            float: right;
        }
    }

    .advice--loan-link {
        text-decoration: none;
        margin-left: calc(13% + 20px);
    }
}

section.stand-alone-loan-slider {
    padding: 40px 0 50px;

    .loan-slider-wrapper {
        margin-bottom: 25px;
    }

    .loan-center--cta button {
        margin-top: 0;
    }
}

section.loan-faq {
    background: #fff;
    padding: 65px 0 75px;

    @media (max-width: $md) {
        padding: 20px 0 30px;
    }

    h1 {
        @media (max-width: $md) {
            margin-top: 15px;
        }
    }

    .loan-faq--list {
        margin-top: 50px;
        list-style: none;
        columns: 2;
        column-gap: 30px;

        @media (max-width: $md) {
            margin-top: 0;
            columns: 1;
            column-gap: 0;

            &:last-of-type {
                li {
                    &:first-of-type {
                        border-top: none;
                    }
                }
            }
        }

        li {
            padding: 20px 5px;
            border-top: 1px solid #b2b2b2;
            display: inline-block;
            width: 100%;

            &:last-of-type {
                border-bottom: 1px solid #b2b2b2;
            }

            p.large {
                @media (min-width: $md-max) {
                    cursor: pointer;
                }
            }

            .faq-answer {
                &.hide {
                    max-height: 0;
                    opacity: 0;
                    transition: none;
                    visibility: hidden;
                    display: table-column;
                }

                transition: all .2s ease-in;
                max-height: 300px;
                visibility: visible;
                opacity: 1;
            }
        }
    }
}

.scroll-arrow {
    position: absolute;
    right: -5px;
    height: 80px;
    max-height: 100%;
    top: 6px;

    .sub-nav--inline & {
        top: 0;
    }

    @media (min-width: $md) {
        display: none;
    }
}

nav.category-links {
    height: 95px;
    background: #ededed;
    overflow: hidden;

    @media (max-width: $md) {
        max-height: 80px;
    }

    .wrapper {
        @media (max-width: $md) {
            padding: 0;
        }
    }

    .category-links-container {
        padding: 20px 0 15px;
        width: 100%;
        margin: 0 auto;

        @media (max-width: $md) {
            padding: 9px 0 0px;
        }

        ul {
            margin: 0 auto;
            list-style: none;
            display: flex;
            -js-display: flex;
            justify-content: center;

            @media (max-width: $md) {
                justify-content: flex-start;
                padding-left: 25px;
                width: 100%;
            }

            li {
                float: left;
                display: inline-block;
                text-align: center;
                margin-right: 45px;
                min-width: 87px;

                &:last-of-type {
                    margin-right: 0;
                }

                @media (max-width: $md) {
                    margin-right: 40px;

                    &:last-of-type {
                        margin-right: 40px;
                    }
                }

                a {
                    text-decoration: none;
                    font-weight: 700;
                    transition: all .5s;

                    &:hover {
                        color: #999;
                        fill: #999;
                        transition: all .5s;
                    }
                }
            }
        }

        svg {
            display: block;
            margin: 0 auto 10px;
            height: 38px;
            max-width: 54px;
        }

        p {
            font-size: 14px;
        }
    }
}

section.dms {
    background-color: #ededed;
    height: 70px;
    padding-top: 10px;
    position: absolute;
    left: 0;
    right: 0;


    @media (max-width: $md) {
        padding-bottom: 70px;
        height: auto;
    }

    .dms--wrapper {
        width: 100%;
        max-width: 1080px;
        margin: 0 auto;
    }
}


section.usp-boxes {
    display: flex;
    position: relative;
    padding: 60px 0;

    @media (max-width: 1100px) {
        display: flex;
    }

    @media (max-width: $md) {
        padding: 25px 10px 45px;
    }

    .usp-boxes--box {
        width: 320px;
        margin-right: 60px;
        float: left;

        &:last-of-type {
            margin-right: 0;
        }

        background-color: #fff;
        padding: 4px;
        text-align: center;
        padding-bottom: 18px;
        box-shadow: 0px 0px 20px #ccc;

        @media (max-width: 1100px) {
            lost-column: 1/3;
        }

        @media (max-width: $md) {
            lost-column: 1/1;
            margin-bottom: 25px;
            lost-utility: center;

            &:last-of-type {
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }

        &.medium {
            margin-top: -70px;

            img {
                max-height: 132px;
                object-fit: cover;
            }

            @media (max-width: $md) {
                lost-column: 1/1;
                margin-top: -20px;
                margin-bottom: 40px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        .usp-boxes--img {
            display: block;
            max-width: 100%;
            width: 100%;
            padding-bottom: 11px;
        }

        .usp-boxes--titel {
            font-family: 'Roboto Slab', serif;
            font-size: 20px;
            font-weight: bold;
        }

        .usp-boxes--text {
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 15px;
        }
    }
}

section.usp-2 {
    padding: 35px 0 60px;

    .usp-2-box {
        lost-column: 1/3 6;

        @media (max-width: $md) {
            lost-column: 1/1;
            margin-bottom: calc(65px/2);
        }

        svg {
            margin-bottom: 25px;

            @media (max-width: $md) {
                margin-bottom: 20px;
            }
        }

        h3 {
            margin-bottom: 15px;
        }
    }
}

ul.mini-usp {
    margin-top: 40px;
    list-style: none;
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (min-width: $xs) and (max-width: $md) {
        flex-direction: column;
        align-items: center;
    }

    @media (max-width:330px) {
        display: none;
    }

    li {
        width: 31%;
        padding: 3px;
        float: left;
        box-shadow: 0px 0 11px #eee;

        @media (min-width: $xs) and (max-width: $md) {
            max-width: 340px;
            width: 100%;
            float: none;
            margin-bottom: 10px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        img {
            float: left;
            height: 55px;
            margin-right: 15px;
        }

        h5 {
            padding-top: 7px;
        }
    }
}

section.banner {
    height: 400px;
    max-height: 400px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    color: #fff;
    position: relative;
    padding-bottom: 15px;

    @media (max-width: 1240px) {
        @apply bg-warm-yellow;
        height: auto;
        max-height: 100%;
        background-size: 175%;
        background-position: 55% 0;
    }

    @media (max-width: $sm) {
        background-size: cover;
        min-height: 200px;
    }

    .banner-wrapper {
        width: 100%;
        max-width: 1080px;
        margin: 0 auto;
        width: 100%;
        position: relative;
        padding-top: 107px;
        height: 100%;

        @media (max-width: 1240px) {
            padding-top: 25px;
        }

        .banner--title {
            font-family: $font-roboto;
            font-size: 80px;
            font-weight: bold;
            margin-bottom: 30px;
            line-height: 52px;

            @media (max-width: 1240px) {
                font-size: 40px;
                line-height: 45px;
                margin-top: 70px;
            }

            @media (max-width: $sm) {
                margin-top: 25px;
                margin-bottom: 0;
            }
        }

        .banner--teaser {
            font-family: $font-source;
            font-size: 40px;
            line-height: 26px;
            font-weight: 400;
            margin-bottom: 0;

            @media (max-width: 1240px) {
                font-size: 21px;
                line-height: 30px;
                font-weight: 300;
                padding: 0 80px;
                margin-top: 45px;
                margin-bottom: 0;
            }

            @media (max-width: $sm) {
                margin-top: 25px;
            }
        }

        .btn-ghost-white {
            margin-top: 45px;
            margin-bottom: 50px;
            padding: 18px 33px;
            background-color: rgb(255, 255, 255);
            color: rgb(0, 155, 192);
            font-size: 27px;

            @media (max-width: 1240px) {
                margin-bottom: 40px;
            }

            @media (max-width: $sm) {
                margin-top: 25px;
            }
        }

        .banner--splash {
            width: 181px;
            height: 181px;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.7);
            position: absolute !important;
            left: 0;
            bottom: -35px;
            padding: 10px 20px 0;
            z-index: 600;

            @media (max-width: 1240px) {
                position: absolute;
                width: 130px;
                height: 130px;
                right: 10px;
            }

            @media (max-width: $sm) {
                left: -8px;
                bottom: -25px;
            }

            .banner--splash-text {
                padding: 10px 20px 0;
                position: absolute;
                top: 30px;
                left: 0;
                right: 0;
                margin: auto;

                @media(max-width:1240px) {
                    top: 12px;
                }

                @media(max-width: $sm) {
                    top: 27px;
                    padding: 10px 19px 0;
                }

                p {
                    font-family: $font-roboto;
                    font-size: 20px;
                    font-weight: bold;

                    @media (max-width: 1240px) {
                        font-size: 13px;
                        line-height: 16px;
                    }
                }
            }
        }
    }

    .dms {
        padding: 0 10px;

        .dms-text {
            padding-bottom: 5px;

            p {
                opacity: .5;
            }
        }
    }
}

section.banner-category {
    background-color: #fff;
    padding: 45px 0 40px;

    .banner-category--top {
        @media (min-width:1024px) {
            p.center-text {
                padding: 0 100px;
            }
        }
    }
}

.info--text-headline {
    section.info {
        padding: 0 1.5rem;

        h2 {
            margin: 0;
        }
    }
}

section.info {
    padding: 50px 70px;

    @media (max-width: $md) {
        padding: 50px 0 25px;
    }

    h2 {
        margin-bottom: 50px;
    }

    .info--text-column {
        max-width: 50%;
        width: 100%;
        float: left;
        padding: 1.5rem;

        .info {
            padding: 0;
        }
        /*&:nth-child(2) {
            padding-right: 25px;
        }

        &:last-of-type {
            padding-left: 25px;
        }*/
        img {
            display: block;
            margin: 0 auto;
        }

        @media (max-width: $md) {
            lost-column: 1/1;
            max-width: 100%;
            padding-left: 0 !important;
            margin-bottom: 25px;

            &:first-of-type {
                padding-right: 0;
            }
        }
    }
}

section.half-n-half {
    @apply bg-warm-yellow;
    position: relative;

    @media (max-width: 1100px) and (min-width: 991px) {
        padding: 0 !important;
    }

    .row {
        display: flex;
        align-items: stretch;
        height: 100%;

        @media (max-width: $md) {
            flex-direction: column;
        }

        > div {
            width: 50vw;
            position: relative;
            float: left;
            text-align: center;
            margin: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-height: 31.25vw; // (800/1280 = 62.5) / 2
            @media(max-width: $md) {
                width: 100vw;
                min-height: 62.5vw;
            }

            .col-half--text-box {
                max-width: 520px;
                width: 100%;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                h3 {
                    font-size: 17px;
                    font-family: $font-source;
                    margin-bottom: 35px;
                }

                h4 {
                    text-transform: uppercase;
                }

                @media (max-width: $md) {
                    position: relative;
                    padding: 35px 10px 0;
                    margin-bottom: 15px;
                    justify-content: flex-start;

                    h2 {
                        margin-bottom: 20px;

                        @media (max-width: $sm-max) {
                            font-size: 28px;
                        }
                    }
                }

                @media (max-width: $sm-max) {
                    padding: 20px;
                }

                .no-flexboxlegacy & {
                    bottom: 50px;
                }
            }

            .box-link-text {
                @apply absolute z-50 h-12 uppercase text-22 font-bold inset-0 m-auto max-w-[190px] w-full inline-flex bg-green-dark text-warm-yellow items-center justify-center leading-tight;
            }

            @media (max-width: $md) {
                width: 100%;

                &:nth-child(2n) {
                    order: 2;
                }
            }

            img {
                object-fit: cover;
                width: 100%;
                top: 0 !important;
                height: 100%;
            }

            div {
                .about {
                    max-width: 315px;
                }
            }
        }
    }

    .row:nth-child(2n) {
        > div:first-of-type {
            order: 1;
        }
    }
}

section.CTA-boxes {
    background-color: #FFE450;

    @media (max-width: 1100px) and (min-width: 991px) {
        padding: 0 !important;
    }

    .row {
        -js-display: flex;
        display: flex;

        @media (max-width: 1290px) {
            flex-direction: column;
            align-items: center;
        }

        > div {
            position: relative;
            width: 50%;
            float: left;
            overflow: hidden;
            text-align: center;
            display: -webkit-box;
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            margin: 0;
            min-height: 400px;
            max-height: 799px;

            @media (max-width: 1290px) {
                width: 100%;
                float: none;
            }

            @media (max-width: $md) {
                min-height: 200px;
            }

            &:first-of-type {
                background-image: url(../../src/img/peter.png), url(../../src/img/yellow-bg.png);
                background-position: 0 20%;
                background-size: 100%, cover;
                background-repeat: no-repeat, no-repeat;

                @media (max-width: $md) {
                    background-position: -249px -95px, 50%;
                    background-size: 175%, cover;
                    background-repeat: no-repeat, no-repeat;
                    padding: 10px;
                }

                @media (max-width:$sm) {
                    background-position: -106px -8px,50%;
                    background-size: 102%,cover;
                }

                @media (max-width: $md-max) {
                    position: relative;
                    padding: 50px 0 140px;
                }

                @media (max-width: $md) {
                    padding: 50px 0 100px;
                }

                @media (max-width:$sm) {
                    max-height: 230px;
                    background-image: none;

                    .col-half--text-box {
                        top: -40px;

                        h2 {
                            font-size: 28px;
                            margin-bottom: 0;
                        }

                        .cta-about {
                            margin-top: 15px;
                        }
                    }
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            div {
                &.about {
                    display: block;
                    margin: auto;
                    margin-top: 80px;
                    max-width: 430px;
                    margin-left: 45%;
                    padding-right: 30px;

                    h2 {
                        font-size: 50px;
                    }

                    @media (max-width: $md-max) {
                        position: relative;
                    }

                    @media (max-width: $md) {
                        margin-left: 45%;
                        margin-top: 0;
                    }

                    @media (max-width:$sm) {
                        margin-left: 15px;

                        h2 {
                            font-size: 46px;
                        }

                        p.medium {
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }

    .row:nth-child(2n) {
        > div:first-of-type {
            float: right;
        }
    }
}

section.product-details {
    background: #fff;
    padding-top: 50px;

    @media (max-width: $md) {
        padding-top: 20px;
    }

    .wrapper {
        display: flex;

        @media (max-width: $md) {
            flex-direction: column;
        }
    }

    .lSSlideOuter {
        flex: 1;

        @media (max-width: $md-max) {
            lost-column: 1/1;
        }

        img {
            position: relative !important;
            max-width: 525px;
            height: 100%;
            object-fit: contain;
            max-height: 345px;
            margin: 0 auto;
            display: block;
        }

        @media (max-width:$md) {
            lost-column: 1/1;
            margin-bottom: 20px;

            .zoomWrapper {
                margin-bottom: 10px;
                height: auto !important;
            }

            img {
                position: relative !important;
                width: 100% !important;
                height: auto !important;
            }
        }

        #gal1 {
            padding-top: 20px;
            text-align: center;
            max-width: 515px;

            @media (max-width: $sm) {
                max-width: 100vw;
                margin: 0;
                padding: 0;
            }
        }
    }

    .lSPager.lSGallery {
        width: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px !important;

        img {
            max-width: 50px;
        }
    }

    .product-details--info {
        flex: 1;

        @media (max-width: $md-max) {
            margin-right: auto !important;
            margin-left: auto !important;
            max-width: 600px;
            width: 100%;
            float: none !important;
            margin-top: 25px;
        }

        .specs-price {
            padding: 10px;
            margin-top: 25px;

            .specs-price--container {


                div.col-one-third {
                    lost-column: 1/3;

                    @media (max-width: $sm) {
                        lost-column: 1/1;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-bottom: 25px;
                    }
                }

                div.col-two-third {
                    lost-column: 2/3;

                    @media (max-width: $sm) {
                        lost-column: 1/1;
                    }
                }
            }

            .product--price-box--cash {
                margin-top: 5px;
                font-size: 20px;
            }

            .energy-label {
                max-height: 47px;
            }
        }

        .buy-btn {
            text-align: left;

            a {
                margin-top: 25px;

                &:first-of-type {
                    margin-right: 25px;

                    @media (max-width: $sm) {
                        margin-right: 15px;
                        padding: 10px 20px;

                        svg {
                            left: 0;
                        }
                    }
                }
            }



            .activate-loan {
                svg {
                    position: relative;
                    top: 3px;
                }
            }
        }
    }

    .product-details--tab-section {
        lost-column: 1/1;
        margin-top: 30px;
    }

    .product-img-slider-container {
        ul {
            li {
                img {
                    width: 100%;
                }
            }
        }
    }
}

.energy-label-productlist {
    display: inline-block;
    width: 100%;
    text-align: left;
    padding-left: 30px;
    margin-top: 1rem;

    img {
        height: 100%;
        max-height: 47px;
    }
}

section.basket-info {
    background: #fff;
    padding: 30px 0;

    @media (max-width: $sm) {
        padding: 30px 0;
    }

    .wrapper {
        @media (max-width: $md-max ) {
            display: flex;
            flex-direction: column;

            div.col-one-third {
                order: 1;
                margin-bottom: 25px;
            }

            div.col-two-third {
                order: 2;
            }
        }
    }

    p.small {
        margin-bottom: 5px;

        @media (max-width: $sm) {
            margin-bottom: 10px;
        }
    }

    .mobile-order-box {
        margin-bottom: 25px;

        .order-box {
            border: none;
        }
    }

    div.form-radio-text {
        float: right;
        max-width: 363px;
        width: 100%;
    }

    .order-box {
        border: 1px solid #ccc;
        padding-top: 20px;
        border-bottom: none;
        height: 100%;

        h4 {
            display: block;
            margin: 0 auto;
            margin-bottom: 25px;
            text-align: center;
        }

        @media (max-width: $md-max) {
            border: none;
        }

        form {
            width: 100%;
            padding: 0 15px;
        }

        .order-box-product {
            width: 100%;
            /*-js-display: flex;
            display: flex;
            flex-direction: row;*/
            position: relative;

            .close-btn {
                justify-content: flex-end;
                position: absolute;
                left: auto !important;
                right: 0;
                height: 10px !important;
                width: 10px !important;
                z-index: 100;
            }

            .order-box-product--img {
                margin-right: 20px;
                max-width: 97px;
                width: 100%;
                padding-left: 15px;
                float: left;
            }

            .order-box-product--info {
                max-width: 162px;
                width: 100%;
                float: left;

                ul {
                    list-style: none;
                }
            }
        }

        .order-box-price {
            padding: 0 10px 20px;
            margin-top: 20px;
            width: 100%;
        }

        .mobile-order-box & {
            border: none;
        }
    }

    .order-box--checks {
        border: 1px solid #ccc;
        border-top: none;
        padding: 20px 15px;

        .order-box--checks-list {
            li {
                width: 100%;
                list-style: none;
                margin-bottom: 10px;
                padding-left: 25px;
                position: relative;

                &:before {
                    content: "";
                    position: absolute;
                    background: url(../../src/img/svg/checkmark.svg);
                    background-repeat: no-repeat;
                    background-size: 100%;
                    width: 16px;
                    height: 16px;
                    left: 0;
                    top: 5px;
                }

                h5 {
                    font-size: 14px;
                }
            }
        }
    }

    form {
        > div {
            @media (max-width: $md) {
                margin-bottom: 35px;
            }
        }

        .formgroup-inner {
            width: 50%;

            @media (max-width: $md) {
                width: 100%;
            }
        }

        @media(max-width: $md) {
            input[name=birth] {
                margin-left: 0 !important;
            }
        }

        .formgroup {


            @media (max-width: $md) {
                margin-bottom: -15px;
            }

            .valgroup {
                lost-column: 1/2;
                float: left;
                position: relative;

                .input-validation-error {
                    border: 1px solid #ff0000;
                    background-color: #ffeeee;
                }

                .field-validation-error {
                    position: absolute;
                    right: 10px;
                    top: 5px;
                    font-size: 11px;
                    font-style: italic;
                }

                input {
                    width: 100%;
                    margin: 0;
                }

                @media (max-width: $md) {
                    max-width: 100%;
                    width: 100%;

                    input {
                        margin-left: 0 !important;
                        margin-bottom: 15px;
                    }
                }
            }

            .formgroup-inner {
                width: 50%;
                float: left;

                @media (max-width: $sm) {
                    width: 100%;
                }

                .valgroup {
                    width: 70%;
                    float: left;

                    input {
                        width: 100%;
                    }

                    &:first-of-type {
                        width: 22%;
                        margin-right: 10px;

                        @media (max-width: $sm) {
                            width: 27%;
                        }
                    }
                }
            }

            input {
                color: #000;

                &::checked {
                    .form-radio-button & {
                        background: orange;
                    }
                }
            }
        }

        .form-radio-button {
            background: #ededed;
            margin-bottom: 5px;
            padding-left: 70px;
            position: relative;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-right: 75px;

            @media (min-width: $xs) and (max-width: $md) {
                padding-right: 40px;
            }



            &.highlight {
                @apply bg-warm-yellow;
            }

            .form-radio-text {
                @media (min-width: $xs) and (max-width: $md) {
                    float: right;
                    max-width: 73%;
                }
            }

            input[type=radio] {
                position: absolute;
                width: 25px;
                height: 100%;
                left: 25px;
                display: inline-block;
                vertical-align: center;
                top: 0;
                bottom: 0;

                @media (min-width: $xs) and (max-width: $md) {
                    height: 25px;
                    top: 22px;
                    left: 25px;
                    width: 25px !important;
                }
            }

            svg {
                float: left;
                margin-right: 20px;
                clear: both;
                height: 69px;
                max-width: 92px;

                @media (min-width: $xs) and (max-width: $md) {
                    margin-right: 10px;
                    height: 45px;
                    max-width: 45px;
                }
            }
        }
    }
}

.terms {
    @media (max-width: $md) {
        margin-bottom: 40px;
    }

    .terms-link {
        font-weight: bold;
    }

    ul {
        margin-left: 40px;
        list-style: disc;
    }

    .checkbox-inline {
        display: inline-block;
        margin-bottom: 10px;
        width: 100%;
    }

    label {
        display: inline-block;
        width: 95%;
        float: right;
        font-size: 13px;
        font-family: Source Sans Pro, sans-serif;

        @media (max-width: $sm-max) {
            width: 92%;
        }
    }

    input {
        width: 16px !important;
        height: 16px !important;
        float: left;
        display: inline-block;
        margin-right: 7px;

        @media (max-width: $md) {
            margin-right: 3px;
        }

        &[type="checkbox"] {
            -webkit-appearance: none;
            border: 1px solid #a1a1a1;
            position: relative;

            &:checked {
                &:after {
                    content: 'x';
                    position: absolute;
                    margin: 0 auto;
                    color: #a1a1a1;
                    top: -2px;
                    left: 3px;
                }
            }
        }
    }

    a.btn.cta-green {
        @media (max-width: $md) {
            margin-left: 30px;
        }
    }
}

section.loan-basket-info {
    background: #fff;
    padding: 30px 0;

    .loanCartNo {
        label {
            @media (max-width: $md) {
                display: block;
                width: 100%;
                max-width: 100%;
                padding-bottom: 5px;
            }
        }
    }

    .order-box {
        border: 1px solid #ccc;
        -js-display: flex;
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .order-box-product {
            -js-display: flex;
            display: flex;
            flex-direction: row;
            position: relative;
            margin-left: -25px;

            .close-btn {
                position: absolute;
                top: 5px;
                right: -20px;
            }

            .order-box-product--img {
                margin-right: 20px;
            }

            .order-box-product--info {
                ul {
                    list-style: none;
                }
            }
        }

        .order-box-price {
            padding: 0 10px 20px;
            margin-top: 20px;
        }
    }

    .order-box--checks {
        margin-top: 40px;
        border: 5px solid #EDEDED;
        padding: 20px 15px;
        margin: 40px auto 0;

        @media (max-width: $sm-max) {
            width: 100%;
            max-width: 100%;
        }

        @media (max-width: $sm) {
            display: none;
        }

        .order-box--checks-list {
            li {
                width: 100%;
                list-style: none;
                margin-bottom: 10px;
                padding-left: 25px;
                position: relative;

                &:before {
                    content: "";
                    position: absolute;
                    background: url(../../src/img/svg/checkmark.svg);
                    background-repeat: no-repeat;
                    background-size: 100%;
                    width: 16px;
                    height: 16px;
                    left: 0;
                    top: 5px;
                }

                h5 {
                    font-size: 14px;
                }
            }
        }
    }



    select {
        color: #000;
        -webkit-appearance: menulist;
        background: #fff;
    }

    .form-radio-button {
        background: #ededed;
        margin-bottom: 5px;
        padding-left: 70px;
        position: relative;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 75px;

        &.highlight {
            background: rgb(255, 227, 93);
        }

        input[type=radio] {
            position: absolute;
            width: 25px;
            height: 100%;
            left: 25px;
            display: inline-block;
            vertical-align: center;
            top: 0;
            bottom: 0;

            &:checked {
            }
        }

        svg {
            float: left;
            margin-right: 20px;
            clear: both;
            height: 62px;
        }
    }
}

.formgroup {


    .zip {
        max-width: 150px;
        float: left;

        @media (max-width: $sm) {
            max-width: 100px;
        }

        @media (max-width: $md) {
            max-width: 30%;
        }
    }

    .label-city {
        width: auto;
        margin-left: 20px;
    }

    .city {
        max-width: 235px;
        float: right;

        @media (max-width: $sm) {
            max-width: 220px;
        }

        @media (max-width: $md) {
            max-width: 68%;
        }
    }

    .reg-nmb {
        max-width: 150px;
        float: left;

        @media (max-width: $md) {
            max-width: 18%;
        }

        @media (max-width: $sm) {
            max-width: 30%;
        }
    }

    .account-nmb {
        max-width: 270px;
        float: right;

        @media (max-width: $md) {
            max-width: 80%;
        }

        @media (max-width: $sm) {
            max-width: 68%;
        }
    }
}

.loan-container {
    border: 5px solid #ededed;
    border-radius: 0;

    .loan-slider-wrapper {
        > div {
            width: 100%;
        }
    }

    .loan-center--pligt {
        width: 100%;
    }

    .loan-center--payment {
        background-color: #ededed;
        padding: 15px 0 25px;
        margin-top: 30px;
    }
}

.terms {
    @media (max-width: $md) {
        margin-bottom: 40px;
    }

    .terms-link {
        font-weight: bold;
    }

    ul {
        margin-left: 40px;
    }

    .checkbox-inline {
        display: inline-block;
        margin-bottom: 0;
        width: 100%;
    }

    label {
        display: inline-block;
        width: 95%;
        float: right;
        font-size: 13px;
        font-family: Source Sans Pro, sans-serif;
        max-width: 100%;
        padding-top: 0;
        margin-bottom: 0;

        @media (max-width: $sm-max) {
            width: 92%;
        }
    }

    input {
        width: 16px !important;
        height: 16px !important;
        float: left;
        display: inline-block;
        margin-right: 7px;

        @media (max-width: $md) {
            margin-right: 3px;
        }

        &[type="checkbox"] {
            -webkit-appearance: none;
            border: 1px solid #a1a1a1;
            position: relative;

            &:checked {
                &:after {
                    content: 'x';
                    position: absolute;
                    margin: 0 auto;
                    color: #a1a1a1;
                    top: -2px;
                    left: 3px;
                }
            }
        }
    }
}



.slider-title {
    margin-bottom: 45px;

    &:last-of-type {
        margin-bottom: 3rem;
    }

    .loan-product--price-box & {
        margin-bottom: 1rem;
    }
}

.clearfix {
    overflow: auto;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.const--amount, .const--month {
    text-align: right;

    p {
        font-weight: bold;
        font-size: 1rem;
        display: inline-block;
        border-bottom: 1px solid #000;
    }
}

section.receipt {
    background: #fff;
    padding: 50px 0 65px;

    @media (max-width: $md) {
        padding: 50px 0 30px;

        .wrapper {
            > div {
                width: 100%;
            }
        }
    }

    .receipt-info {
        ul.receipt-info--list {
            list-style: none;
            display: inline-block;
            margin-top: 15px;
            max-width: 100%;

            @media (min-width: $xs) and (max-width: $md) {
                max-width: 100%;
                margin-bottom: 25px;
            }

            li {
                border-top: 1px solid #000;
                display: inline-block;
                padding: 5px 0;

                &:last-of-type {
                    border-bottom: 1px solid #000;
                }

                width: 100%;

                p {
                    width: 50%;
                    float: left;

                    &:last-of-type {
                        text-align: left;
                    }
                }
            }
        }
    }

    .receipt-info--img {
        position: relative;

        .receipt-info--img-overlay {
            position: absolute;
            text-align: center;
            width: 100%;
            top: 35px;

            &.loan-receipt {
                position: absolute !important;
                height: 100%;
                -js-display: flex;
                margin: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                top: 0 !important;

                h2,
                h4 {
                    color: #fff;
                    top: 0;
                }

                @media (max-width: $md) {
                    padding: 0 15px;
                }
            }

            h2 {
                margin-bottom: 10px;
            }
        }
    }

    .print-options {
        display: flex;
        flex-directoion: row;
        margin-top: 25px;

        @media (max-width: $md) {
            margin-top: 0;
        }

        a {
            text-decoration: none;

            .pdf {
                display: inline-block;
                line-height: 25px;
                margin-left: 30px;
                display: flex;
                align-items: center;

                img {
                    margin-right: 3px;
                }
            }

            .print {
                display: inline-block;
                line-height: 25px;
                display: flex;
                align-items: center;

                svg {
                    margin-right: 5px;
                }
            }
        }
    }
}

section.receipt-order-container {
    background: #fff;
    padding-bottom: 40px;

    @media (max-width: $md) {
        padding: 0 20px;
        padding-bottom: 30px;
    }

    .wrapper {
        border: 1px solid #ededed;
        padding: 35px 30px;

        > div {
            lost-column: 1/2 3;

            @media (max-width: $md) {
                lost-column: 1/1;
            }
        }
    }

    div.receipt-order {
        .order-box-container {
            margin-bottom: 15px;

            > div {
                lost-column: 1/2;
            }

            .order-box {
                h4 {
                    margin-bottom: 20px;
                }

                .order-box-product {
                    padding-top: 10px;

                    ul {
                        list-style: none;
                        max-width: 162px;
                        margin-top: 5px;

                        li {
                            span {
                                float: right;
                            }
                        }
                    }
                }
            }
        }
    }

    .order-box-price-container {
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: $md) {
            height: auto !important;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            > div {
                &:first-of-type {
                    margin-bottom: 25px;
                }
            }
        }
    }

    .receipt-order--checks {
        display: flex;
        justify-content: center;

        ul {
            list-style: none;

            li {
                width: 100%;
                margin: 5px 0;
                -js-display: flex;
                display: flex;

                svg {
                    width: 13px;
                    height: 12px;
                    fill: #2c9246;
                    margin-right: 4px;
                    position: relative;
                    top: 5px;
                }
            }
        }
    }
}

section.assurance {
    padding: 25px 0;

    .trust-pilot {
        @media (max-width: $md) {
            margin-bottom: 30px;
        }

        img {
            &:first-of-type {
                margin-bottom: 25px;
            }
        }
    }
}

section.jobs {
    padding: 50px 70px;

    @media (max-width:330px) {
        padding: 50px 0;
    }

    ul {
        list-style: none;
        width: 100%;

        &:first-of-type {
            margin-bottom: 15px;
            display: inline-block;

            li {
                border-bottom: 1px solid #a1a1a1;
                padding-bottom: 3px;
            }
        }

        a {
            display: inline-block;
            width: 100%;
        }

        li {
            display: inline-block;
            width: 20%;
            float: left;

            &:nth-child(3n+1) {
                width: 60%;
            }
        }

        @media (max-width:330px) {
            &.job-list-header {
                display: none;
            }

            &.job-list {
                display: flex;
                flex-direction: column;
                margin-bottom: 15px;
                border-bottom: 1px solid #000;

                li {
                    &:first-of-type {
                        font-weight: bold;
                    }

                    width: 100%;
                    margin-bottom: 10px;
                }
            }
        }
    }
}
/* Opsalg */
.axapta {
    border: 1px solid #333;
    padding: 5px 15px;
    font-weight: 700;
    margin-top: 30px;

    &.overlay {
        position: absolute;
        z-index: 100;
        background: rgba(0,0,0,.4);
        width: 97%;
        top: 299px;
        color: #fff;
        max-height: 95px;
        user-select: text;

        @media (max-width: $md) {
            display: none;
        }
    }
}

.upsale {
    position: relative;
    background-color: green;
    color: #fff;
    padding: 30px 0;

    .wrapper {
        h2 {
            margin-bottom: 50px;
        }

        > div {
            lost-column: 1/4 8;

            h3 {
                margin-bottom: 15px;
                border-bottom: 1px solid #fff;
                padding-bottom: 3px;
            }
        }

        .axapta {
            border: 1px solid #fff;
            padding: 30px 15px;
        }

        .alternative {
            img {
                float: left;
                margin-right: 10px;
            }
        }

        .arguments {
            ul {
                padding-left: 15px;
            }
        }

        .product--price-box {
            margin-top: 0;
            color: #fff;

            .product--price-box--price {
                color: #fff;
            }
        }
    }
}
/* ----------------- Media Queries ----------------- */
.hidden {
    display: none;
}

.hidden-lg {
    @media (min-width: 1091px) {
        display: none !important;
    }
}

.visible-lg {
    @media (min-width: 0px) and (max-width: 1090px) {
        display: none !important;
    }
}

.hidden-md {
    @media (min-width: $md) and (max-width: $md-max) {
        display: none !important;
    }
}

.hidden-sm {
    @media (min-width: $sm) and (max-width: $md) {
        display: none !important;
    }
}

.hidden-xs {
    @media (min-width: $xs) and (max-width: $xs-max) {
        display: none !important;
    }
}

.col-half {
    @media (min-width: $xs) and (max-width: $xs-max) {
        width: 100%;
    }
}

.hidden-1 {
    display: none;
}

.hidden-2 {
    display: none;
}

.hidden-3 {
    display: none;
}

.hidden-4 {
    display: none;
}
/*Compare*/
@media (min-width: 1160px) {

    #compare-products {
        width: 90vw;
        max-width: 100%;
    }

    .cd-products-comparison-table {
        margin-bottom: 6em;
    }

    .cd-products-comparison-table::after {
        /* never visible - this is used in jQuery to check the current MQ */
        display: none;
        content: 'mobile';
    }

    .cd-products-comparison-table header {
        padding: 30px 20px 0;
        line-height: 100px;
        margin-bottom: 20px;
    }

    .cd-products-comparison-table header::after {
        clear: both;
        content: "";
        display: table;
    }

    .cd-products-comparison-table h2 {
        float: left;
        font-weight: bold;
    }

    .cd-products-comparison-table .filter.active {
        cursor: pointer;
        background-color: #9dc997;
    }

    .no-touch .cd-products-comparison-table .filter.active:hover {
        background-color: #a7cea1;
    }

    @media only screen and (min-width: 1170px) {
        .cd-products-comparison-table {
            margin-bottom: 8em;
        }

        .cd-products-comparison-table::after {
            /* never visible - this is used in jQuery to check the current MQ */
            content: 'desktop';
        }

        .cd-products-comparison-table h2 {
            font-size: 2.4rem;
        }
    }

    .cd-products-table {
        position: relative;
        overflow: hidden;
    }

    .cd-products-table .features {
        /* fixed left column - product properties list */
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 120px;
        border-style: solid;
        border-color: #e6e6e6;
        border-top-width: 1px;
        border-bottom-width: 1px;
        background-color: #fafafa;
        opacity: .95;
    }

    .cd-products-table .features::after {
        /* color gradient on the right of .features -  visible while scrolling inside the .cd-products-table */
        content: '';
        position: absolute;
        top: 0;
        left: 100%;
        width: 4px;
        height: 100%;
        background-color: transparent;
        background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.06), transparent);
        background-image: linear-gradient(to right,rgba(0, 0, 0, 0.06), transparent);
        opacity: 0;
    }

    @media only screen and (min-width: 1170px) {
        .cd-products-table .features {
            width: 210px;
        }
    }

    .cd-products-table.scrolling .features::after {
        opacity: 1;
    }

    .cd-products-wrapper {
        overflow-x: scroll;
        /* this fixes the buggy scrolling on webkit browsers - mobile devices only - when overflow property is applied */
        -webkit-overflow-scrolling: touch;
        border-style: solid;
        border-color: #e6e6e6;
        border-top-width: 1px;
        border-bottom-width: 1px;
    }

    .cd-products-columns {
        /* products list wrapper */
        width: auto;
        margin-left: 120px;
    }

    .cd-products-columns::after {
        clear: both;
        content: "";
        display: table;
    }

    @media only screen and (min-width: 1170px) {
        .cd-products-columns {
            width: auto;
            margin-left: 210px;
        }
    }

    .cd-products-columns .product {
        position: relative;
        float: left;
        //width: 150px;
        text-align: center;
        -webkit-transition: opacity 0.3s, visibility 0.3s, -webkit-transform 0.3s;
        -moz-transition: opacity 0.3s, visibility 0.3s, -moz-transform 0.3s;
        transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
        list-style: none;
    }

    .filtering .cd-products-columns .product:not(.selected) {
        opacity: 0;
        visibility: hidden;
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
    }

    .no-product-transition .cd-products-columns .product.selected {
        -webkit-transition: opacity 0.3s, visibility 0.3s;
        -moz-transition: opacity 0.3s, visibility 0.3s;
        transition: opacity 0.3s, visibility 0.3s;
    }

    .filtered .cd-products-columns .product:not(.selected) {
        position: absolute;
    }

    @media only screen and (min-width: 1170px) {
        .cd-products-columns .product {
            width: 310px;
        }
    }

    .cd-features-list li {
        font-weight: bold;
        padding: 20px 40px;
        border-color: #e6e6e6;
        border-style: solid;
        border-top-width: 1px;
        border-right-width: 1px;
        list-style: none;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .cd-features-list li.rate {
        /* rating stars */
        padding: 21px 0;
    }

    .cd-features-list li.rate span {
        display: inline-block;
        height: 22px;
        width: 110px;
        background: url(../img/cd-star.svg);
        color: transparent;
    }

    @media only screen and (min-width: 1170px) {
        .cd-features-list li.rate {
            padding: 22px 0;
        }
    }

    .features .cd-features-list li,
    .cd-products-table .features .top-info {
        /* fixed left column - items */
        font-weight: bold;
        /* set line-height value equal to font-size of text inside product cells */
        line-height: 14px;
        padding: 20px 10px;
        text-align: left;
    }

    @media only screen and (min-width: 1170px) {
        .features .cd-features-list li,
        .cd-products-table .features .top-info {
            text-transform: uppercase;
            line-height: 16px;
            padding: 25px 20px;
            letter-spacing: 1px;
        }

        .features .cd-features-list li {
            line-height: 19px;
        }
    }

    .features .cd-features-list li {
        /* truncate text with dots */
        text-align: center;
    }

    .cd-products-table .top-info {
        position: relative;
        height: 177px;
        //width: 150px;
        text-align: center;
        padding: 1.25em 2.5em;
        border-color: #e6e6e6;
        border-style: solid;
        border-right-width: 1px;
        -webkit-transition: height 0.3s;
        -moz-transition: height 0.3s;
        transition: height 0.3s;
        cursor: pointer;
        background: #ffffff;
    }

    .cd-products-table .top-info::after {
        /* color gradient below .top-info -  visible when .top-info is fixed */
        content: '';
        position: absolute;
        left: 0;
        top: 100%;
        height: 4px;
        width: 100%;
        background-color: transparent;
        background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.06), transparent);
        background-image: linear-gradient(to bottom,rgba(0, 0, 0, 0.06), transparent);
        opacity: 0;
    }

    .cd-products-table .top-info h3 {
        padding: 1.25em 0 0.625em;
        font-weight: bold;
        font-size: 1.4rem;
    }

    .cd-products-table .top-info img {
        display: block;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        max-height: 125px;
        margin: 0 auto;
    }

    .cd-products-table .top-info h3, .cd-products-table .top-info img {
        -webkit-transition: -webkit-transform 0.3s;
        -moz-transition: -moz-transform 0.3s;
        transition: transform 0.3s;
    }

    .cd-products-table .top-info .check {
        position: relative;
        display: inline-block;
        height: 16px;
        width: 16px;
        margin: 0 auto 1em;
    }

    .cd-products-table .top-info .check::after, .cd-products-table .top-info .check::before {
        /* used to create the check icon and green circle dot - visible when product is selected */
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        height: 100%;
        width: 100%;
    }

    .cd-products-table .top-info .check::before {
        /* green circle dot */
        border-radius: 50%;
        border: 1px solid #e6e6e6;
        background: #ffffff;
        -webkit-transition: background-color 0.3s, -webkit-transform 0.3s, border-color 0.3s;
        -moz-transition: background-color 0.3s, -moz-transform 0.3s, border-color 0.3s;
        transition: background-color 0.3s, transform 0.3s, border-color 0.3s;
    }

    .cd-products-table .top-info .check::after {
        /* check icon */
        background: url(../img/cd-check.svg) no-repeat center center;
        background-size: 24px 24px;
        opacity: 0;
        -webkit-transition: opacity 0.3s;
        -moz-transition: opacity 0.3s;
        transition: opacity 0.3s;
    }

    @media only screen and (min-width: 1170px) {
        .cd-products-table .top-info {
            height: 280px;
            width: 310px;
        }

        .cd-products-table .top-info h3 {
            padding-top: 1.4em;
            font-size: 1.6rem;
        }

        .cd-products-table .top-info .check {
            margin-bottom: 1.5em;
        }
    }

    .cd-products-table .features .top-info {
        /* models */
        //width: 120px;
        cursor: auto;
        background: #fafafa;
    }

    @media only screen and (min-width: 1170px) {
        .cd-products-table .features .top-info {
            width: 210px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .cd-products-table .selected .top-info .check::before {
        /* green circle dot */
        background: #9dc997;
        border-color: #9dc997;
        -webkit-transform: scale(1.5);
        -moz-transform: scale(1.5);
        -ms-transform: scale(1.5);
        -o-transform: scale(1.5);
        transform: scale(1.5);
        -webkit-animation: cd-bounce 0.3s;
        -moz-animation: cd-bounce 0.3s;
        animation: cd-bounce 0.3s;
    }

    @-webkit-keyframes cd-bounce {
        0% {
            -webkit-transform: scale(1);
        }

        60% {
            -webkit-transform: scale(1.6);
        }

        100% {
            -webkit-transform: scale(1.5);
        }
    }

    @-moz-keyframes cd-bounce {
        0% {
            -moz-transform: scale(1);
        }

        60% {
            -moz-transform: scale(1.6);
        }

        100% {
            -moz-transform: scale(1.5);
        }
    }

    @keyframes cd-bounce {
        0% {
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            -ms-transform: scale(1);
            -o-transform: scale(1);
            transform: scale(1);
        }

        60% {
            -webkit-transform: scale(1.6);
            -moz-transform: scale(1.6);
            -ms-transform: scale(1.6);
            -o-transform: scale(1.6);
            transform: scale(1.6);
        }

        100% {
            -webkit-transform: scale(1.5);
            -moz-transform: scale(1.5);
            -ms-transform: scale(1.5);
            -o-transform: scale(1.5);
            transform: scale(1.5);
        }
    }

    .cd-products-table .selected .top-info .check::after {
        /* check icon */
        opacity: 1;
    }

    @media only screen and (min-width: 1170px) {
        .cd-products-table.top-fixed .cd-products-columns > li,
        .cd-products-table.top-scrolling .cd-products-columns > li,
        .cd-products-table.top-fixed .features,
        .cd-products-table.top-scrolling .features {
            padding-top: 160px;
        }

        .cd-products-table.top-fixed .top-info,
        .cd-products-table.top-scrolling .top-info {
            height: 160px;
            position: fixed;
            top: 0;
        }

        .no-cssgradients .cd-products-table.top-fixed .top-info, .no-cssgradients .cd-products-table.top-scrolling .top-info {
            border-bottom: 1px solid #e6e6e6;
        }

        .cd-products-table.top-fixed .top-info::after,
        .cd-products-table.top-scrolling .top-info::after {
            opacity: 1;
        }

        .cd-products-table.top-fixed .top-info h3,
        .cd-products-table.top-scrolling .top-info h3 {
            -webkit-transform: translateY(-116px);
            -moz-transform: translateY(-116px);
            -ms-transform: translateY(-116px);
            -o-transform: translateY(-116px);
            transform: translateY(-116px);
        }

        .cd-products-table.top-fixed .top-info img,
        .cd-products-table.top-scrolling .top-info img {
            -webkit-transform: translateY(-62px) scale(0.4);
            -moz-transform: translateY(-62px) scale(0.4);
            -ms-transform: translateY(-62px) scale(0.4);
            -o-transform: translateY(-62px) scale(0.4);
            transform: translateY(-62px) scale(0.4);
        }

        .cd-products-table.top-scrolling .top-info {
            position: absolute;
        }
    }

    .cd-table-navigation a {
        position: absolute;
        z-index: 2;
        top: 0;
        right: 15px;
        -webkit-transform: translateY(55px);
        -moz-transform: translateY(55px);
        -ms-transform: translateY(55px);
        -o-transform: translateY(55px);
        transform: translateY(55px);
        /* replace text with image */
        overflow: hidden;
        text-indent: 100%;
        white-space: nowrap;
        color: transparent;
        height: 60px;
        width: 40px;
        background: rgba(64, 64, 66, 0.8) url("../img/cd-arrow.svg") no-repeat center center;
        border-radius: 3px;
        -webkit-transition: background-color 0.3s, opacity 0.3s, visibility 0.3s, -webkit-transform 0.3s;
        -moz-transition: background-color 0.3s, opacity 0.3s, visibility 0.3s, -moz-transform 0.3s;
        transition: background-color 0.3s, opacity 0.3s, visibility 0.3s, transform 0.3s;
    }

    .cd-table-navigation a.inactive {
        opacity: 0;
        visibility: hidden;
    }

    .cd-table-navigation a.prev {
        left: 120px;
        right: auto;
        -webkit-transform: translateY(55px) translateX(15px) rotate(180deg);
        -moz-transform: translateY(55px) translateX(15px) rotate(180deg);
        -ms-transform: translateY(55px) translateX(15px) rotate(180deg);
        -o-transform: translateY(55px) translateX(15px) rotate(180deg);
        transform: translateY(55px) translateX(15px) rotate(180deg);
    }

    .no-touch .cd-table-navigation a:hover {
        background-color: #404042;
    }

    @media only screen and (min-width: 1170px) {
        .cd-table-navigation a {
            -webkit-transform: translateY(100px);
            -moz-transform: translateY(100px);
            -ms-transform: translateY(100px);
            -o-transform: translateY(100px);
            transform: translateY(100px);
        }

        .cd-table-navigation a.prev {
            left: 210px;
            -webkit-transform: translateY(100px) translateX(15px) rotate(180deg);
            -moz-transform: translateY(100px) translateX(15px) rotate(180deg);
            -ms-transform: translateY(100px) translateX(15px) rotate(180deg);
            -o-transform: translateY(100px) translateX(15px) rotate(180deg);
            transform: translateY(100px) translateX(15px) rotate(180deg);
        }

        .top-fixed .cd-table-navigation a {
            position: fixed;
        }

        .top-fixed .cd-table-navigation a, .top-scrolling .cd-table-navigation a {
            -webkit-transform: translateY(45px);
            -moz-transform: translateY(45px);
            -ms-transform: translateY(45px);
            -o-transform: translateY(45px);
            transform: translateY(45px);
        }

        .top-fixed .cd-table-navigation a.prev, .top-scrolling .cd-table-navigation a.prev {
            -webkit-transform: translateY(45px) translateX(15px) rotate(180deg);
            -moz-transform: translateY(45px) translateX(15px) rotate(180deg);
            -ms-transform: translateY(45px) translateX(15px) rotate(180deg);
            -o-transform: translateY(45px) translateX(15px) rotate(180deg);
            transform: translateY(45px) translateX(15px) rotate(180deg);
        }
    }
    /* -------------------------------- 

No JS

-------------------------------- */
    .no-js .actions {
        display: none;
    }

    .no-js .cd-products-table .top-info {
        height: 145px;
    }

    @media only screen and (min-width: 1170px) {
        .no-js .cd-products-table .top-info {
            height: 248px;
        }
    }

    .no-js .cd-products-columns .check {
        display: none;
    }
}
/* -------------- Loan Form Checkmarks -------------------------*/
.formgroup {
    position: relative;
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #7ac142;
    fill: none;
    width: 19px;
    height: 19px;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark-form {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 0;
    box-shadow: inset 0px 0px 0px #7ac142;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    position: absolute;
    left: 95%;
    bottom: 14px;
    margin: 0;

    @media (max-width:768px) {
        left: 90%;
    }
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
    width: 9px;
    height: 6px;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0%, 100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #7ac142;
    }
}

.checkmark-IE {
    height: 20px;
    width: 20px;
    transition: all .3s ease-in-out;
    position: absolute;
    left: 95%;
    bottom: 45%;

    @media (max-width:768px) {
        left: 90%;
    }
}

.input-validation-error ~ .checkmark-form {
    display: none !important;
}

.input-validation-error ~ .checkmark-IE {
    display: none !important;
}
// Black Friday styles
.black-friday .customer-center-btn {
    @media screen and (max-width: 500px) {
        display: none;
    }
}


.black-friday .hero-unit {
    background-image: none !important;
    background-color: #111111;
    color: #fff;
    display: block;
}

section.black-friday-overlay {
    background: #111111;
    min-height: calc(100vh - 140px);
    display: flex;
    align-items: center;


    * {
        color: #fff !important;
    }

    .wrapper {
        max-width: 520px;
        display: flex;
        flex-direction: column;
        align-items: center;

        div {
            text-align: center;
        }

        h1 {
            text-transform: uppercase;
            font-size: 54px;
            margin-bottom: 10px;

            @media screen and (max-width: 500px) {
                font-size: 38px;
            }
        }


        h2 {
            font-family: "Source Sans Pro", sans-serif;
            text-align: center;
            font-size: 22px;
            font-weight: 300;

            @media screen and (max-width: 500px) {
                line-height: normal;
            }
        }

        h3 {
            font-family: "Source Sans Pro", sans-serif;
            font-size: 34px;
            text-transform: uppercase;
            margin-bottom: 10px;

            @media screen and (max-width: 500px) {
                font-size: 26px;
            }
        }

        p {
            font-size: 20px;
            font-weight: 300;
        }
    }

    .btn.bf-red {
        background-color: #FF124A;
        border: 1px solid #fff;
        font-size: 36px;
        font-style: italic;
    }

    .bf-form {
        margin-top: 45px;
    }

    form {
        margin-top: 25px;

        .input-group {
            text-align: left;

            label, input {
                width: 100%;
                margin-bottom: 15px;
            }

            label {
            }

            input {
                background-color: #ccc;
                border-radius: 7px;
                padding: 25px 10px;
            }
        }

        .input-group-checkbox {
            margin-bottom: 40px;
            display: flex;
            text-align: left;

            input {
                display: block;
                width: 60px;
                height: 25px;
            }
        }
    }
}

section.hero-unit .hero-unit-wrapper.bf {
    padding: 25px 0;
    width: 100%;

    .hero-unit-product {
    }

    h1 {
        margin-bottom: 15px;
        text-transform: uppercase;
        font-size: 64px;
    }

    h2 {
        font-family: "Source Sans Pro", sans-serif;
        text-align: center;
        font-size: 22px;
        font-weight: 300;
        margin-bottom: 20px;

        @media screen and (max-width: 500px) {
            line-height: normal;
        }
    }

    .order-box--checks-list {
        margin-bottom: 20px;
        list-style: none;
        display: flex;
        justify-content: space-around;
        max-width: 860px;
        margin: 0 auto 20px;

        @media screen and (max-width:500px) {
            flex-direction: column;

            li {
                margin-bottom: 15px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        li {
            position: relative;
            font-weight: bold;
            font-size: 16px;

            @media screen and (max-width:500px) {
                max-width: 250px;
                margin: 0 auto;
                width: 100%;
            }

            &:before {
                content: "";
                position: relative;
                background: url(../../src/img/svg/checkmark.svg);
                background-repeat: no-repeat;
                background-size: 100%;
                width: 24px;
                height: 24px;
                top: 0;
                display: inline-block;
                float: left;
                left: -10px;

                @media screen and (max-width:500px) {
                    left: 0;
                }
            }
        }
    }
}

.countdown-bf {
    color: #fff;
    width: 100%;
    max-width: 420px;
    margin: 0 auto 25px;

    @media screen and (max-width: 500px) {
        padding: 0 30px;
    }

    h4 {
        font-weight: 400;
        text-align: center;
    }

    span {
        font-weight: 300;
    }
}

.counter-list {
    display: flex;
    justify-content: space-around;
}

.counter {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 5px;

    span {
        font-size: 10px;
        font-style: normal;
    }

    div {
        background: #333333;
        width: 100%;
        text-align: center;
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        margin-top: 5px;

        p {
            font-style: normal;
            font-weight: 300;
            font-size: 40px !important;
        }
    }

    &:last-of-type {
        div {
            margin-right: 0;
        }
    }
}

.bf-sticky-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 30px 0;
    background: #111;
    color: #fff;
    z-index: 100;
    transform: translateY(170px);
    transition: transform .3s ease-in-out;

    @media screen and (max-width:500px) {
        padding: 15px 0 30px;
    }

    &.open {
        transform: translateY(0);
        transition: transform .3s ease-in-out;
    }

    p {
        color: #fff;

        @media screen and (max-width:500px) {
            font-size: 20px;
        }
    }

    .contact {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        width: 100%;

        @media screen and (max-width: 500px) {
            flex-direction: column;
            align-items: center;
        }

        .contact--text {
            display: flex;
            align-items: center;

            @media screen and (max-width:500px) {
                margin-top: 15px;
            }
        }

        .icon--phone {
            margin-left: 40px;
            fill: #fff;

            @media screen and (max-width:500px) {
                margin-right: 15px;
                margin-left: -15px;
            }
        }
    }

    .contact a.contact--number {
        color: #fff;
    }

    .isOpen {
        width: 100%;
        display: inline-block;
        text-align: center;
        position: relative;
        top: 15px;

        p {
            font-size: 13px;
        }
    }

    .openClosePhone {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: #148228;
        border: 1px solid #bed0c0;
        margin-right: 5px;
        display: inline-block;
    }
}
/* Debitor Consent */
/* Leasy */
.debitor__consent--l {
    min-height: calc(100vh - 95px);

    ul {
        margin-left: 30px;
    }

    .form-info {
        margin-top: 2rem;

        button.btn {
            background: #3ca54b;
        }

        .formgroup {
            margin-bottom: 1rem;

            .valgroup {
                padding: .5rem 0;

                label {
                    display: inline-block;
                    min-width: 50px;
                }
            }
        }
    }

    button {
        padding: 15px 30px;
    }
    /*.checkbox-inline {


        input[type="checkbox"] {
            width: 16px !important;
            height: 16px !important;
            float: left;
            display: inline-block;
            margin-right: 36px;
        }

        label {
            display: inline-block;
            width: 85%;
            float: left;
            font-size: 16px;
            font-family: Source Sans Pro,sans-serif;
            max-width: 100%;
            padding-top: 0;
            margin-bottom: 1.5rem;
        }
    }*/
}
/* DER */
#content.page {

    .debitor__consent--der {
        min-height: calc(100vh - 95px);
        background: transparent;

        h1 {
            margin-top: 120px;
        }

        ul {
            margin-left: 30px;
        }

        .background.video {
            overflow: scroll;
        }



        .form-info {
            margin-top: 2rem;

            button.btn {
                background-color: #ec8931;
                padding: 15px 30px;
            }

            .formgroup {
                margin-bottom: 1rem;

                .valgroup {
                    padding: .5rem 0;

                    label {
                        display: inline-block;
                        min-width: 50px;
                    }
                }
            }
        }

        .checkbox-inline {


            input[type="checkbox"] {
                position: relative !important;
                width: 16px !important;
                height: 16px !important;
                float: left;
                display: inline-block;
                margin-right: 36px;
            }

            label {
                display: inline-block;
                width: 40%;
                float: left;
                font-size: 13px;
                font-family: Source Sans Pro,sans-serif;
                max-width: 100%;
                padding-top: 0;
                margin-bottom: 1.5rem;
            }
        }
    }
}

.mandatory {
    color: red;
    font-weight: bold;
    padding-left: 0px;
}

.no-info {
    padding-top: 2rem;
    display: none;

    p {
        font-size: 12px;
        color: #333;
        font-family: Source Sans Pro,sans-serif;
    }
}


.icon-info {
    height: 28px;
    color: white;
    font-size: 18px;
    line-height: 28px;
    width: 28px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: 18px auto;
    background-position: center center;
    background-image: url(/Spargo.Assets/images/infoicon.png);
    margin-top: 3px;
    cursor: pointer;
    position: absolute;
    right: -30px;
    top: -2px;

    @media screen and (max-width:500px) {
        right: 0;
    }
}

.tooltip {
    max-width: 400px;
    width: 100%;
    position: absolute;
    top: 40px;
    right: 0;
    z-index: -1;
    opacity: 0;
    transition: opacity .3s ease-out;
    background: #FFF5C3;
    padding: 1rem 1.5rem;

    &-active {
        opacity: 1;
        z-index: 999;
    }
}

.errorlog {
    color: red;
    margin-top: 15px;
    margin-bottom: 0px;
}
// Loancart info NO

.loanCartNo {
    div.eier, div.leier, div.cardata, div.studentdata, div.loandata {
        display: none;
    }
}

.input-validation-error {
    border: 1px solid #ff0000;
    background-color: #ffeeee;
}

.black-friday-message {
    text-align: center;
    background: #fff;
    padding: 3rem 0;

    @media screen and (max-width: 768px) {
        padding: 2rem
    }

    h3 {
        color: red;
    }
}

.usp {
    font-family: Source Sans Pro,sans-serif;
    font-size: 18px;
    margin: 10px auto 40px;
    text-align: left;
    max-width: 290px;
}

.usp ul li span {
    color: #39a64a !important;
    font-size: 20px;
    font-weight: bold;
    margin-right: 7px;
}

.usp ul {
    list-style: none;
}
/*Contact Form*/

.contact-form {
    .form {
        max-width: 600px;
        margin: 0 auto;
    }

    h3 {
        margin-bottom: 1rem;
    }

    p {
        margin-bottom: 2rem;
    }

    .input-group {
        display: flex;
        margin-bottom: 1rem;

        .validation-group {
            display: flex;
            flex-direction: column;

            .field-validation-error {
                margin-top: .5rem;
                color: red;
            }
        }

        select {
            appearance: textfield;
            margin-right: 0;
        }

        textarea {
            height: 130px;
            padding-left: 10px;
            padding-top: 6px;
        }

        textarea, input, select, input, .validation-group {
            width: 100%;
        }

        label {
            margin-right: 1rem;
            font-weight: bold;
            min-width: 150px;
        }
    }

    button {
        margin-top: 2rem;
    }
}



button[type=submit] {
    outline: none;
    appearance: none;
    border: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    padding: 10px 25px;

    svg {
        width: 24px;
        height: 24px;
        margin-left: 1rem;
    }
}

.basket-terms {
    ul {
        list-style: disc;
        padding-left: 1rem;
    }
}

.rte-cta {
    padding-bottom: .5rem;
    padding-top: .5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    background: #3ca54b;
    font-size: 1rem;
    color: white;
}

.rte-cta a {
    color: white !important;
}
