@media print{

	@page{
		size:a4;
	}

	.receipt-info--img, .usp-boxes, .quick-icons, .header--top-mobile, .main-nav {
		display: none !important;
	}

	.contact{
		float: right !important;
	}

	.col-half{
		width: 800px;
		margin: 0 auto !important;
		float: none !important;
	}

	.receipt-info p:first-of-type{
		text-align: center;
	}

	ul.receipt-info--list{
		margin: 0 auto !important;
    	display: block !important;
    	max-width: 500px !important;
    	margin-top: 50px !important;
	}

	footer{
		border-top: 1px solid #000;
		padding-top:25px;
	}

}


